import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/common/Loader";
import MessageAlert from "../../components/common/MessageAlert";
import axios from "../../api/axios";

const AddScannedConsignmentForm = () => {
    const { id, lot_id, millScannedConsignmentDataId } = useParams();
    const [submitting, setSubmitting] = useState(false); // Submitting state
    const [successMsg, setSuccessMsg] = useState(""); // Error state
    const [error, setError] = useState(""); // Error state
    const [loading, setLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [formData, setFormData] = useState({
        barcodeData: "",
        dia: "",
        length: "",
        species: "",
        millNo: "",
        grade: "",
    }); // Form data state

    const navigate = useNavigate();

    const calculateCFT = (length, dia) => {
        if (!length || !dia || isNaN(length) || isNaN(dia)) return "0.00";
        const cft = (length * dia * dia * 2.2072) / 10000; // Correct formula from Excel
        return cft.toFixed(2); // Rounded to 2 decimal places
    };
    const cft = calculateCFT(formData.length, formData.dia);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const { dia, length } = formData;
        if (!dia || !length) {
            setError("Please enters required fields");
            return false;
        }
        if (isNaN(dia) || isNaN(length)) {
            setError("Length and Diameter must be numeric.");
            return false;
        }
        setError("");
        return true;
    };

    const submitForm = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            setSubmitting(true);
            await axios.put(
                isEditMode
                    ? `/app/mill/UpdateScannedBarcodeData/`
                    : `/app/mill/addScannedBarcodeData/`,
                {
                    ...formData,
                    ...(isEditMode && { millScannedConsignmentDataId }), // Add conditionally
                    consignmentId: id,
                    lot_id: lot_id,
                    // cft,
                }
            );
            setSuccessMsg(
                isEditMode
                    ? "Scanned QR data updated successfully."
                    : "Scanned QR data added successfully."
            );
            navigate(`/view-scanned-lots/${id}/${lot_id}`); // Corrected dynamic route
        } catch (err) {
            if (err.response && err.response.data.message) {
                setError(err.response.data.message); // Show specific error from API
            }
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        setIsEditMode(!!millScannedConsignmentDataId); // Simplified condition
    }, [millScannedConsignmentDataId]);

    useEffect(() => {
        if (millScannedConsignmentDataId) {
            const fetchLot = async () => {
                try {
                    setLoading(true);
                    const response = await axios.get(
                        `/app/mill/editScannedBarcodeData?millScannedConsignmentDataId=${millScannedConsignmentDataId}`
                    );

                    const { barcodeData, dia, length, species, millDetailId, grade } = response.data;
                    setFormData({
                        barcodeData,
                        dia,
                        length,
                        species,
                        millNo: millDetailId,
                        grade,
                    });
                } catch (err) {
                    setError("Failed to fetch scanned QR data details.");
                } finally {
                    setLoading(false);
                }
            };

            fetchLot();
        }
    }, [millScannedConsignmentDataId]);

    if (loading) return <Loader className="h-60 bg-white" />;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">
                {isEditMode ? "Edit" : "Add"} Consignment QR code Data
            </h1>{" "}
            {error && <MessageAlert type="error" message={error} onClose={() => setError("")} />}
            <form onSubmit={submitForm} className="space-y-6 bg-[#f5f5f5] p-6 shadow rounded">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">QR Code </label>
                        <input
                            type="text"
                            name="barcodeData"
                            value={formData.barcodeData}
                            onChange={handleChange}
                            className="mt-1 p-2 block w-full  border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Length <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="number"
                            name="length"
                            value={formData.length}
                            onChange={handleChange}
                            className="mt-1 p-2 block w-full  border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            SED (Dia)<span className="text-red-500">*</span>
                        </label>
                        <input
                            type="number"
                            name="dia"
                            value={formData.dia}
                            onChange={handleChange}
                            className="mt-1 p-2 block w-full  border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">CFT </label>
                        <input
                            type="text"
                            value={cft}
                            readOnly
                            className="mt-1 p-2 block w-full bg-gray-100 border-gray-300 rounded-md shadow-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Species </label>
                        <input
                            name="species"
                            value={formData.species}
                            onChange={handleChange}
                            className="mt-1 p-2 block w-full  border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Mill No </label>
                        <input
                            name="millNo"
                            value={formData.millNo}
                            onChange={handleChange}
                            className="mt-1 p-2 block w-full  border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Grade </label>
                        <input
                            name="grade"
                            value={formData.grade}
                            onChange={handleChange}
                            className="mt-1 p-2 block w-full  border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                </div>
                <button
                    disabled={submitting}
                    type="submit"
                    className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md shadow hover:bg-blue-700"
                >
                    {submitting ? "Submitting..." : isEditMode ? "Save Changes" : "Submit"}
                </button>
            </form>
        </div>
    );
};

export default AddScannedConsignmentForm;
