import React, { useState, useRef, useEffect } from "react";
import axios from "../../api/axios";
import MessageAlert from "../../components/common/MessageAlert";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";

const initialValues = {
    supplier: "",
    species: "",
};

const AddEditConsignment = () => {
    const { id } = useParams(); // ID for edit
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [allValid, setAllValid] = useState(false);
    const [filter, setFilter] = useState("all"); // Options: "all", "valid", "invalid"
    const fileInputRef = useRef(null);
    const [submitting, setSubmitting] = useState(false); // State to manage form submission
    const [loading, setLoading] = useState(false); // State to manage loader visibility
    const navigate = useNavigate();

    const [selectedSpecies, setSelectedSpecies] = useState({});
    const [selectedSupplier, setSelectedSupplier] = useState({});
    const [formFields, setFormFields] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const [isEditMode, setIsEditMode] = useState(false);
    const [speciesOptions, setSpeciesOptions] = useState([]); // To store dynamic species options
    const [supplierOptions, setSupplierOptions] = useState([]); // To store dynamic species options

    useEffect(() => {
        if (id) {
            setIsEditMode(true); // Enable edit mode if `id` exists
            setAllValid(true);
        } else {
            setIsEditMode(false); // Otherwise, it's an add form
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            const fetchLot = async () => {
                try {
                    const response = await axios.get(`/consignment/${id}`);
                    const { supplier } = response.data.Consignment;
                    setFormFields({
                        supplier,
                    });
                } catch (err) {
                    setError("Failed to fetch supplier details.");
                }
            };

            fetchLot();
        }
    }, [id]);

    useEffect(() => {
        // Fetch the species options from an API or source
        const fetchSpeciesOptions = async () => {
            try {
                const response = await axios.get("/speciesDropdown"); // Your API endpoint for fetching species
                const data = await response.data.species;
                const transformedData = data.map((item) => ({
                    value: item.id,
                    label: item.species_name + " ( " + item.main_category_name + " ) ",
                    category: item.main_category_name?.toLowerCase(),
                })); // Transform the data to match the expected format

                setSpeciesOptions(transformedData); // Assuming data is an array of species
            } catch (error) {
                console.error("Error fetching species options:", error);
            }
        };

        const fetchSupplierOptions = async () => {
            try {
                const response = await axios.get("/supplierDropdown"); // Your API endpoint for fetching supplier
                const data = await response.data.consignment;
                const transformedData = data.map((item) => ({
                    value: item.id,
                    label: item.supplier + " ( " + item.consignmentNo + " ) ",
                })); // Transform the data to match the expected format

                setSupplierOptions(transformedData); // Assuming data is an array of species
            } catch (error) {
                console.error("Error fetching species options:", error);
            }
        };

        fetchSpeciesOptions();
        fetchSupplierOptions();
    }, []); // Fetch species on component mount

    const handleFileUpload = (e) => {
        setFile(e.target.files[0]);
    };

    const handleValidation = async () => {
        if (!selectedSpecies.value) {
            setError("Please select species.");
            return;
        }

        if (!selectedSupplier.value) {
            setError("Please select supplier.");
            return;
        }

        if (!file) {
            setError("Please select a file to upload.");
            return;
        }
        setLoading(true); // Show loader

        const formData = new FormData();
        formData.append("file", file);
        formData.append("speciesId", selectedSpecies.value);
        formData.append("consignmentId", selectedSupplier.value);

        try {
            const response = await axios.post(
                `/consignment/${
                    selectedSpecies.category == "hardwood" ? "/validateHardwood" : "/validatePine"
                }`,
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );

            if (response.data.invalidRows.length > 0) {
                setError("Invalid entries found. Please reupload the file after corrections.");
                // Reset file input and related states
                setFile(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = ""; // Reset file input element
                }
                setAllValid(false);
                // return;
            }

            // Combine valid and invalid rows for display
            const combinedData = [
                ...response.data.invalidRows.map((row) => ({ ...row, status: "Invalid" })),
                ...response.data.validRows.map((row) => ({ ...row, status: "Valid" })),
            ];

            setData(combinedData);

            // Check if all rows are valid
            const allRowsValid = combinedData.every((row) => row.status === "Valid");
            setAllValid(allRowsValid);
            setError("");
        } catch (err) {
            setError(err.response?.data?.message);
        } finally {
            setLoading(false); // Hide loader
        }
    };

    const handleSave = async () => {
        const errors = {};

        if (!selectedSpecies.value) errors.species = "Species is required.";
        if (!selectedSupplier.value) errors.supplier = "Supplier is required.";

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }

        if (!allValid) {
            setError("Cannot submit. Ensure all rows are valid.");
            return;
        }

        const validRows = data.filter((row) => row.status === "Valid");

        if (!isEditMode && validRows.length === 0) {
            setError("There should be at least one valid record.");
            return;
        }

        try {
            setSubmitting(true);
            // Create a FormData object
            const formData = new FormData();
            formData.append("speciesId", selectedSpecies.value);
            formData.append("consignmentId", selectedSupplier.value);

            if (!isEditMode) {
                formData.append("logs", JSON.stringify(validRows)); // Attach the valid rows as a JSON string
                formData.append("file", file); // Attach the file

                // Make the request with the FormData
                await axios.post(
                    selectedSpecies.category === "hardwood"
                        ? "/uploadConsignmentHardwood"
                        : "/uploadConsignmentPine",
                    formData,
                    {
                        headers: { "Content-Type": "multipart/form-data" },
                    }
                );
                fileInputRef.current.value = null; // Clear the input's value
            } else {
                // Make the request with the FormData
                await axios.put(`/consignment/${id}`, formData, {});
            }

            navigate("/consignment-management");
        } catch (err) {
            const backendErrorMessage =
                err.response?.data?.message || "Failed to save data. Please try again.";
            setError(backendErrorMessage); // Save the error message from the backend
        } finally {
            setSubmitting(false);
        }
    };

    // Filtered rows based on the selected filter
    const filteredData =
        filter === "all"
            ? data
            : data.filter((row) =>
                  filter === "valid" ? row.status === "Valid" : row.status === "Invalid"
              );

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-2">
                {isEditMode ? "Edit Consignment" : "Upload Consignment"}
            </h1>
            <Breadcrumbs aria-label="breadcrumb" className="pb-4">
                <Link underline="hover" className="text-gray-600 text-xs font-bold" to="/consignment-Management">
                Consignment Management
                </Link>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Upload Consignment
                </Link>
            </Breadcrumbs>


            {error && <MessageAlert type="error" message={error} onClose={() => setError("")} />}

            {!isEditMode && (
                <div>
                    <label className="block text-gray-700 mb-2">Select Species</label>
                    <select
                        name="species"
                        value={selectedSpecies?.value || ""}
                        onChange={(e) => {
                            const { value } = e.target;
                            const selectedSpeciesObj = speciesOptions.find(
                                (species) => species.value === Number(value)
                            );
                            setSelectedSpecies(selectedSpeciesObj || {});
                        }}
                        className={`w-full p-2 border rounded ${
                            selectedSpecies.value ? "text-gray-900" : "text-gray-300"
                        }`}
                    >
                        <option value="" className="text-gray-400">
                            Select a species
                        </option>
                        {speciesOptions.map((species, index) => (
                            <option key={index} value={species.value} className="text-gray-700">
                                {species.label}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            <div className="my-2">
                <label className="block text-gray-700 mb-2">Select Supplier</label>
                <select
                    name="supplier"
                    value={selectedSupplier?.value || ""} // Binding to the supplier id or empty string
                    onChange={(e) => {
                        const { value } = e.target;
                        const selectedSupplierObj = supplierOptions.find(
                            (supplier) => supplier.value === Number(value)
                        );

                        setSelectedSupplier(selectedSupplierObj || {}); // Set the full supplier object
                    }}
                    className={`w-full p-2 border rounded ${
                        selectedSupplier.value ? "text-gray-900" : "text-gray-300"
                    }`}
                >
                    <option value="">Select a supplier</option>
                    {supplierOptions.map((supplier, index) => (
                        <option key={index} value={supplier.value}>
                            {" "}
                            {/* Value is the supplier id */}
                            {supplier.label} {/* Display supplier label */}
                        </option>
                    ))}
                </select>
            </div>

            {!isEditMode && (
                <div className="mt-4 mb-6">
                    <label className="block text-lg font-medium text-gray-800 mb-2">
                        Upload Excel
                    </label>
                    <button
                        type="button"
                        className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                        onClick={() => fileInputRef.current.click()} // Triggers the file input click
                    >
                        Choose File
                    </button>
                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileUpload}
                        ref={fileInputRef} // Attach the ref to the input
                        className="hidden" // Hide the default file input
                    />
                </div>
            )}

            {!isEditMode && (
                <button
                    onClick={handleValidation}
                    disabled={loading || submitting} // Disable button while loading
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 mr-4"
                >
                    {loading ? "Validating..." : "Validate"}
                </button>
            )}

            <button
                onClick={handleSave}
                disabled={!allValid || submitting} // Disable submit button if not all rows are valid
                className={`px-4 py-2 rounded ${
                    allValid || !submitting
                        ? "bg-green-600 text-white hover:bg-green-700"
                        : "bg-gray-400 text-gray-700 cursor-not-allowed"
                }`}
            >
                {submitting ? "Submitting..." : isEditMode ? "Save Changes" : "Submit Valid Data"}
            </button>

            {/* Statistics Block */}
            {!isEditMode && data.length > 0 && (
                <div className="flex justify-between items-center mt-6 p-4 bg-gray-100 rounded shadow">
                    <div>
                        <p className="text-sm font-bold text-gray-600">
                            Total Entries: {data.length}
                        </p>
                        <p className="text-sm font-bold text-green-600">
                            Valid Entries: {data.filter((row) => row.status === "Valid").length}
                        </p>
                        <p className="text-sm font-bold text-red-600">
                            Invalid Entries: {data.filter((row) => row.status === "Invalid").length}
                        </p>
                    </div>
                    <div>
                        <label className="text-sm font-bold text-gray-600 mr-2">Filter:</label>
                        <select
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            className="p-2 border rounded"
                        >
                            <option value="all">All</option>
                            <option value="valid">Valid Entries</option>
                            <option value="invalid">Invalid Entries</option>
                        </select>
                    </div>
                </div>
            )}

            {/* Table for Pine Species Logs */}
            {!isEditMode && filteredData.length > 0 && selectedSpecies.category == "hardwood" && (
                <div className="overflow-y-auto mt-4" style={{ maxHeight: "400px" }}>
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="sticky top-0 bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-2 text-left text-sm font-medium">Row</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Log No.</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Length</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Dia</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Hollow</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Volume</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">CFT</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Lot No.</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Status</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Error</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((row, index) => (
                                <tr
                                    key={index}
                                    className={`hover:bg-gray-50 ${
                                        row.status === "Invalid" ? "bg-red-50" : "bg-green-50"
                                    }`}
                                >
                                    <td className="px-4 py-2 text-sm text-gray-800">{index + 1}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">
                                        {row.log_no}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-800">
                                        {row.length}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{row.dia}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">
                                        {row.hollow || ""}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-800">
                                        {row.volume}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{row.cft}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">
                                        {row.lot_no}
                                    </td>
                                    <td
                                        className={`px-4 py-2 text-sm font-bold ${
                                            row.status === "Invalid"
                                                ? "text-red-600"
                                                : "text-green-600"
                                        }`}
                                    >
                                        {row.status}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-800">
                                        {row.error || ""}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {!isEditMode && filteredData.length > 0 && selectedSpecies.category != "hardwood" && (
                <div className="overflow-y-auto mt-4" style={{ maxHeight: "400px" }}>
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="sticky top-0 bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-2 text-left text-sm font-medium">Row</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">barcode</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Lot</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">SubLot</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Grade</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Length</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Dia</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">CBM</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Status</th>
                                <th className="px-4 py-2 text-left text-sm font-medium">Error</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((row, index) => (
                                <tr
                                    key={index}
                                    className={`hover:bg-gray-50 ${
                                        row.status === "Invalid" ? "bg-red-50" : "bg-green-50"
                                    }`}
                                >
                                    <td className="px-4 py-2 text-sm text-gray-800">{index + 1}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">
                                        {row.barcode}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{row.lot}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">
                                        {row.sublot}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{row.grade}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">
                                        {row.length || ""}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{row.dia}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{row.cbm}</td>

                                    <td
                                        className={`px-4 py-2 text-sm font-bold ${
                                            row.status === "Invalid"
                                                ? "text-red-600"
                                                : "text-green-600"
                                        }`}
                                    >
                                        {row.status}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-800">
                                        {row.error || ""}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default AddEditConsignment;
