import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axiosInstance from "../../api/axios";
import { formatDate } from "../../utils/formatDate";
import { useDebounce } from "use-debounce";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

const AddLogStepTwo = ({ stepOneData, setStep, selectedSpecieCategory, refetchLocalStorageData }) => {
    const [message, setMessage] = useState(null); // Success/Fail message
    const [loading, setLoading] = useState(false);
    const isHardwood = selectedSpecieCategory == "hardwood";
    const [savedData, setSavedData] = useState(null); // To store fetched data
    const [searching, setSearching] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        clearErrors,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        setLoading(true);
        setMessage(null);

        try {
            const payload = {
                ...data,
                ...stepOneData,
                ifBroken: Number(getValues("ifBroken")),
                date: formatDate(stepOneData.date),
            };
            await axiosInstance.post(
                isHardwood
                    ? "/app/addScannedBarcodeDataHardwood"
                    : "/app/addScannedBarcodeDataSoftwood",
                payload
            );
            setMessage({ type: "success", text: "Submission successful!" });
            reset(); // Clear only Step 2 form fields
        } catch (err) {
            setMessage({
                type: "error",
                text: err.response?.data?.message || "Submission failed.",
            });
        } finally {
            setLoading(false);
        }
    };

    // Fetch data from localStorage safely
    useEffect(() => {
        try {
            const data = localStorage.getItem("globalLogData");
            if (data) {
                setSavedData(JSON.parse(data));
                setMessage(null); // Clear any previous error message
            }
        } catch (error) {
            console.log("Error retrieving data from localStorage.");
        }
    }, [refetchLocalStorageData]);

console.log("Saved Data:", savedData);


    
    // Watch the log_no and length fields
    const log_no = watch("log_no");
    const length = watch("length");

    // Debounce the log_no and length input values
    const [debouncedLogNo] = useDebounce(log_no, 500); // 500ms delay
    const [debouncedLength] = useDebounce(length, 500);

    // Effect to trigger search when debounced values change
    useEffect(() => {
        if (debouncedLogNo || debouncedLength) {
            handleSearchHardwoodData(debouncedLogNo, debouncedLength);
        }
    }, [debouncedLogNo, debouncedLength]);

    const handleSearchHardwoodData = async (log_no, length) => {
        // Validate log_no and length before making the API call
        if (!log_no) {
            setMessage({
                type: "error",
                text: "Log No is required.",
            });
            return;
        }

        try {
            setSearching(true);
            // Make the API call
            const response = await axiosInstance.get(
                `/app/scanBarcodeHardwood?species_id=${stepOneData.species_id}&log_no=${log_no}&length=${length}`
            );

            // Check if the API call is successful
            // if (response.data.message === "Barcode scanned successfully.") {
                setMessage({
                    type: "success",
                    text: response.data.message,
                });

                // Prefill the form with dia and hollow values
                setValue("dia", response.data.data.dia);
                setValue("hollow", response.data.data.hollow);
                setValue("length", response.data.data.length);
                clearErrors();
            // } else {
            //     setMessage({
            //         type: "error",
            //         text: "An error occurred while scanning the barcode.",
            //     });
            // }
        } catch (error) {
            // Check if the error response is a 400 for already scanned barcode
            if (
                error.response?.status === 400 &&
                error.response?.data?.message === "Barcode already scanned."
            ) {
                setMessage({
                    type: "warning", // Show warning for already scanned barcode
                    text: "This barcode has already been scanned.",
                });

                // Prefill the form with the dia and hollow values from the error response
                setValue("dia", error.response?.data?.data.dia);
                setValue("grade", error.response?.data?.data.grade);
                clearErrors();
            } else {
                // For other errors, handle them as general errors
                setMessage({
                    type: "error",
                    text: error.response?.data?.message || "Submission failed.",
                });
            }
        } finally {
            setSearching(false);
        }
    };

    const barcodeData = watch("barcodeData");

    const [debouncedBarcode] = useDebounce(barcodeData, 500);

    // Effect to trigger search when debounced values change
    useEffect(() => {
        if (debouncedBarcode) {
            handleSearchSoftwoodData(debouncedBarcode);
        }
    }, [debouncedBarcode]);

    const handleSearchSoftwoodData = async (barcodeData) => {
        // Validate barcodeData before making the API call
        if (!barcodeData) {
            setMessage({
                type: "error",
                text: "QR code is required.",
            });
            return;
        }
        try {
            setSearching(true);
            // Make the API call
            const response = await axiosInstance.get(
                `/app/scanBarcodeSoftwood?species_id=${stepOneData.species_id}&lot_id=${stepOneData.lot_id}&barcodeData=${barcodeData}`
            );

            // Check if the API call is successful
            // if (response.data.message === "Barcode scanned successfully.") {
                setMessage({
                    type: "success",
                    text: response.data.message,
                });

                // Prefill the form with length and grade values
                setValue("length", response.data.data.length);
                setValue("grade", response.data.data.grade);
            // } else {
            //     setMessage({
            //         type: "error",
            //         text: "An error occurred while scanning the barcode.",
            //     });
            // }
        } catch (error) {
            // Check if the error response is a 400 for already scanned barcode
            if (
                error.response?.status === 400 &&
                error.response?.data?.message === "Barcode already scanned."
            ) {
                setMessage({
                    type: "warning", // Show warning for already scanned barcode
                    text: "This barcode has already been scanned.",
                });

                // Prefill the form with length and grade values from the error response
                setValue("length", error.response?.data?.data.length);
                setValue("grade", error.response?.data?.data.grade);
            } else {
                // For other errors, handle them as general errors
                setMessage({
                    type: "error",
                    text: error.response?.data?.message || "Submission failed.",
                });
            }
        } finally {
            setSearching(false);
        }
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                    to="/consignment-Management"
                >
                    Consignment Management
                </Link>
                <div
                    onClick={() => setStep(1)}
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                >
                    Add Log (Step One)
                </div>
                <Link
                    underline="hover"
                    className="text-gray-600 text-xs font-bold cursor-pointer"
                    href="#"
                >
                    Add Log (Step Two)
                </Link>
            </Breadcrumbs>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <h2 className="text-xl font-bold">Step 2: Log Details</h2>

                {searching ? (
                    <p className="text-gray-900 text-sm font-medium">Searching...</p>
                ) : (
                    message && (
                        <p
                            className={`text-sm ${
                                message.type === "success" ? "text-green-600" : "text-red-600"
                            }`}
                        >
                            {message.text}
                        </p>
                    )
                )}

                <div className="bg-white p-4 rounded shadow-md">
                    <h3 className="text-xl">Date: {formatDate(stepOneData.date)}</h3>
                    <p>
                        <strong>Location:</strong> {savedData?.location}
                    </p>
                    <p>
                        <strong>Species:</strong> {savedData?.species}
                    </p>
                    <p>
                        <strong>Species Type:</strong> {savedData?.speciesType}
                    </p>
                    <p>
                        <strong>Lot:</strong> {savedData?.lot}
                    </p>
                    <p>
                        <strong>Vehicles Number:</strong> {savedData?.vehiclesNumber}
                    </p>
                    <p>
                        <strong>Gate Pass No:</strong> {savedData?.gatePassNumber}
                    </p>
                    <p>
                        <strong>No of Logs:</strong> {savedData?.noOfLog}
                    </p>
                    <p>
                        <strong>No of Logs (Gate Pass):</strong> {savedData?.noOfLogsGatepass}
                    </p>
                    <p>
                        <strong>Actual Received Pieces:</strong> {savedData?.actualReceivedPieces}
                    </p>
                </div>

                <div className="flex gap-5">
                    {isHardwood ? (
                        <div>
                            <label className="block text-gray-700">Log No</label>
                            <input
                                {...register("log_no", { required: "Log No is required" })}
                                className="w-full p-2 border rounded"
                            />
                            {errors.log_no && (
                                <p className="text-red-500">{errors.log_no.message}</p>
                            )}
                        </div>
                    ) : (
                        <div>
                            <label className="block text-gray-700">QR Code</label>
                            <input
                                {...register("barcodeData", { required: "QR Code is required" })}
                                className="w-full p-2 border rounded"
                            />
                            {errors.barcodeData && (
                                <p className="text-red-500">{errors.barcodeData.message}</p>
                            )}
                        </div>
                    )}
                    {isHardwood && (
                        <div>
                            <label className="block text-gray-700">Length</label>
                            <input
                                {...register("length", { required: "Length is required" })}
                                className="w-full p-2 border rounded"
                            />
                            {errors.length && (
                                <p className="text-red-500">{errors.length.message}</p>
                            )}
                        </div>
                    )}
                </div>

                {!isHardwood && (
                    <div>
                        <label className="block text-gray-700">Length</label>
                        <input
                            {...register("length", { required: "Length is required" })}
                            className="w-full p-2 border rounded"
                        />
                        {errors.length && <p className="text-red-500">{errors.length.message}</p>}
                    </div>
                )}

                {isHardwood && (
                    <div className="flex gap-5">
                        <div>
                            <label className="block text-gray-700">Dia</label>
                            <input
                                {...register("dia", { required: "Dia is required" })}
                                className="w-full p-2 border rounded"
                            />
                            {errors.dia && <p className="text-red-500">{errors.dia.message}</p>}
                        </div>

                        {/* Check Hollow Request Radio */}
                        <div>
                            <label className="block text-gray-700">Check Hollow Request</label>
                            <div>
                                <label className="mr-4">
                                    <input
                                        {...register("checkHollowRequest", { required: true })}
                                        type="radio"
                                        value={1}
                                        defaultChecked
                                        className="mr-2"
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        {...register("checkHollowRequest", { required: true })}
                                        type="radio"
                                        value={2}
                                        
                                        className="mr-2"
                                    />
                                    No
                                </label>
                            </div>
                        </div>

                        <div>
                            <label className="block text-gray-700">Hollow</label>
                            <input
                                {...register("hollow", { required: "Hollow is required" })}
                                className="w-full p-2 border rounded"
                            />
                            {errors.hollow && (
                                <p className="text-red-500">{errors.hollow.message}</p>
                            )}
                        </div>
                    </div>
                )}

                <div>
                    <label className="block text-gray-700">Is Broken?</label>
                    <div>
                        <label>
                            <input
                                {...register("ifBroken")}
                                type="radio"
                                value={1}
                                className="mr-2"
                            />
                            Yes
                        </label>
                        <label className="ml-4">
                            <input
                                {...register("ifBroken")}
                                type="radio"
                                value={0}
                                defaultChecked
                                className="mr-2"
                            />
                            No
                        </label>
                    </div>
                </div>

                {!isHardwood && (
                    <div>
                        <label className="block text-gray-700">Grade</label>
                        <input
                            {...register("grade", { required: "Grade is required" })}
                            className="w-full p-2 border rounded"
                        />
                        {errors.grade && <p className="text-red-500">{errors.grade.message}</p>}
                    </div>
                )}

                <div className="flex justify-between">
                    <button
                        type="button"
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                        onClick={() => setStep(1)} // Navigate back to Step 1
                    >
                        Back
                    </button>

                    <button
                        type="submit"
                        className={`bg-blue-600 text-white px-4 py-2 rounded ${
                            false ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={false}
                    >
                        {false ? "Submitting..." : "Submit"}
                    </button>
                </div>
            </form>
        </>
    );
};

export default AddLogStepTwo;
