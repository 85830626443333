import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import StepOne from "./SupplierMaster/AddLogStepOne";
import StepTwo from "./SupplierMaster/AddLogStepTwo";

const AddConsignment = () => {
    const [step, setStep] = useState(1); // Step navigation
    const [stepOneData, setStepOneData] = useState(null); // Store Step 1 data
    const [selectedSpecieCategory, setSelectedSpecieCategory] = useState("");
    const [refetch, setRefetch] = useState(false);

    const handleStepOneSubmit = (data) => {
        setStepOneData(data); // Save Step 1 data
        setStep(2); // Move to Step 2
        setRefetch(prev => !prev);

    };


    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Add Log</h1>

            <div className="bg-white shadow rounded-lg p-6 space-y-6">
                {step === 1 && (
                    <StepOne
                        onSubmit={handleStepOneSubmit}
                        initialData={stepOneData}
                        setSelectedSpecieCategory={setSelectedSpecieCategory}
                    />
                )}
                {step === 2 && (
                    <StepTwo
                        refetchLocalStorageData={refetch}
                        stepOneData={stepOneData}
                        setStep={setStep}
                        selectedSpecieCategory={selectedSpecieCategory}
                    />
                )}
            </div>
        </div>
    );
};

export default AddConsignment;
