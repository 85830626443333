import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "../../api/axios";
import {
    Autocomplete,
    Breadcrumbs,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

const AddLogStepOne = ({ onSubmit, initialData, setSelectedSpecieCategory }) => {
    const {
        register,
        handleSubmit,
        control,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: initialData || {},
    });

    const [selectedSpeciesType, setSelectedSpeciesType] = useState(""); // State for Species Type

    const [speciesloading, setspeciesLoading] = useState(false);
    const [speciesOptions, setSpeciesOptions] = useState([]);
    const [locationsloading, setlocationsLoading] = useState(false);
    const [locationOptions, setLocationOptions] = useState([]);
    const [lotsloading, setlotsLoading] = useState(false);
    const [lotOptions, setLotOptions] = useState([]);

    // Function to fetch species based on a search term
    const fetchSpeciesOptions = async (searchKey = "", main_category_id = "") => {
        try {
            setspeciesLoading(true);
            const response = await axiosInstance.get("/speciesDropdown", {
                params: { search: searchKey, main_category_id : main_category_id || selectedSpeciesType }, // Assuming the API accepts 'search' query parameter for filtering
            });
            const data = await response.data.species;
            const transformedData = data.map((item) => ({
                value: item.id,
                label: item.species_name,
                category: item.main_category_name?.toLowerCase(),
            }));
            setSpeciesOptions(transformedData);
        } catch (error) {
            console.error("Error fetching species options:", error);
        } finally {
            setspeciesLoading(false);
        }
    };

    const fetchLocationOptions = async (searchKey = "") => {
        try {
            setlocationsLoading(true);
            const response = await axiosInstance.get("/locationDropdown", {
                params: { search: searchKey }, // Assuming the API accepts 'search' query parameter for filtering
            });
            const data = await response.data.locations;
            const transformedData = data.map((item) => ({
                value: item.id,
                label: item.name + " ( " + item.code + " ) ",
            }));
            setLocationOptions(transformedData);
        } catch (error) {
            console.error("Error fetching location options:", error);
        } finally {
            setlocationsLoading(false);
        }
    };

    const fetchLotOptions = async (searchKey = "") => {
     const  {species_id} =   getValues()
        
        try {
            setlotsLoading(true);
            const response = await axiosInstance.get("/lotDropdown", {
                params: { search: searchKey, speciesId : species_id || selectedSpeciesType }, // Assuming the API accepts 'search' query parameter for filtering
            });
            const data = await response.data.lots;
            const transformedData = data.map((item) => ({
                value: item.lot_id,
                label: item.lot_id,
            }));
            setLotOptions(transformedData);
        } catch (error) {
            console.error("Error fetching lot options:", error);
        } finally {
            setlotsLoading(false);
        }
    };

    // Handle species type change
    const handleSpeciesTypeChange = (event) => {
        setSelectedSpeciesType(event.target.value);
        // Fetch species options based on the selected species type
        // fetchSpeciesOptions();
        fetchSpeciesOptions("", event.target.value);

        setValue("species_id", "");
        setValue("lot_id", "");
    };

    // Fetch initial species data when the component mounts
    useEffect(() => {
        fetchSpeciesOptions();
        fetchLocationOptions();
        fetchLotOptions();
    }, []);


    const handleFormSubmit = async () => {
        // First, validate and submit the form
        await handleSubmit(onSubmit)(); // Ensure form validation happens

        // Get the form values after successful submission
        const data = getValues();

        // Extract the labels for location, species, lot, and species type
        const locationLabel = locationOptions.find(
            (option) => option.value === data.location_id
        )?.label;
        const speciesLabel = speciesOptions.find(
            (option) => option.value === data.species_id
        )?.label;
        const speciesTypeLabel = selectedSpeciesType === "1" ? "HardWood" : "Softwood (Pine)";
        const lotLabel = lotOptions.find((option) => option.value === data.lot_id)?.label;

        // Create the saved data object with all fields
        const savedData = {
            date: data.date,
            location: locationLabel,
            species: speciesLabel,
            speciesType: speciesTypeLabel,
            lot: lotLabel, // Save the label of the selected lot
            vehiclesNumber: data.VehiclesNumber,
            gatePassNumber: data.gatePassNumber,
            noOfLog: data.no_of_log,
            noOfLogsGatepass: data.no_of_logs_gatepass, // Save the new field
            actualReceivedPieces: data.actual_received_pieces, // Save the new field
        };

        // Save the data to localStorage
        localStorage.setItem("globalLogData", JSON.stringify(savedData));
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" className="text-gray-600 text-xs font-bold" to="/consignment-Management">
                Consignment Management
                </Link>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Add Log (Step One)
                </Link>
            </Breadcrumbs>

            <form onSubmit={handleFormSubmit} className="space-y-4">
                <h2 className="text-xl font-bold">Step 1: Log Enviorment Setup</h2>

                <div>
                    <label className="block text-gray-700">Date</label>
                    <Controller
                        name="date"
                        control={control}
                        rules={{ required: "Date is required" }}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                selected={field.value}
                                onChange={(date) => {
                                    field.onChange(date);
                                }}
                                dateFormat="yyyy-MM-dd"
                                className="w-full p-2 border rounded"
                                autoFocus // Ensure focus is set on the field automatically
                            />
                        )}
                    />
                    {errors.date && <p className="text-red-500">{errors.date.message}</p>}
                </div>

                <div>
                    <label className="block text-gray-700">Location</label>
                    <Controller
                        name="location_id"
                        control={control}
                        rules={{ required: "Location is required" }}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={locationOptions}
                                getOptionLabel={(option) => option.label || ""}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                loading={locationsloading}
                                onChange={(_, selectedOption) => {
                                    // Update the form state with the selected option's value
                                    field.onChange(selectedOption?.value || "");
                                }}
                                onInputChange={(_, newInputValue) => {
                                    // Trigger search when the input changes
                                    fetchLocationOptions(newInputValue?.split(" ")[0]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!errors.location_id}
                                        helperText={
                                            errors.location_id ? errors.location_id.message : ""
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {locationsloading && (
                                                        <span className="loading-spinner">
                                                            Loading...
                                                        </span>
                                                    )}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                disableClearable
                            />
                        )}
                    />
                </div>

                {/* Species Type Field */}
                <div className="mb-4">
                    <label className="block text-gray-700">Species Type</label>
                    <RadioGroup value={selectedSpeciesType} onChange={handleSpeciesTypeChange} row>
                        <FormControlLabel value="1" control={<Radio />} label="HardWood" />
                        <FormControlLabel value="2" control={<Radio />} label="Softwood (Pine)" />
                    </RadioGroup>
                </div>

                <div>
                    <label className="block text-gray-700">Species</label>
                    <Controller
                        name="species_id"
                        control={control}
                        rules={{ required: "Species is required" }}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={speciesOptions}
                                getOptionLabel={(option) => option.label || ""}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                loading={speciesloading}
                                onChange={(_, selectedOption) => {
                                    // Update the form state with the selected option's value
                                    field.onChange(selectedOption?.value || "");
                                    setSelectedSpecieCategory(selectedOption?.category || "");
                                    fetchLotOptions("", selectedOption?.value);
                                }}
                                onInputChange={(_, newInputValue) => {
                                    // Trigger search when the input changes
                                    fetchSpeciesOptions(newInputValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!errors.species_id}
                                        helperText={
                                            errors.species_id ? errors.species_id.message : ""
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {speciesloading && (
                                                        <span className="loading-spinner">
                                                            Loading...
                                                        </span>
                                                    )}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                disableClearable
                            />
                        )}
                    />
                </div>

                <div>
                    <label className="block text-gray-700">Lot</label>
                    <Controller
                        name="lot_id"
                        control={control}
                        rules={{ required: "Lot is required" }}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={lotOptions}
                                getOptionLabel={(option) => option.label || ""}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                loading={lotsloading}
                                onChange={(_, selectedOption) => {
                                    // Update the form state with the selected option's value
                                    field.onChange(selectedOption?.value || "");
                                }}
                                onInputChange={(_, newInputValue) => {
                                    // Trigger search when the input changes
                                    fetchLotOptions(newInputValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!errors.lot_id}
                                        helperText={errors.lot_id ? errors.lot_id.message : ""}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {lotsloading && (
                                                        <span className="loading-spinner">
                                                            Loading...
                                                        </span>
                                                    )}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                disableClearable
                            />
                        )}
                    />
                </div>

                <div>
                    <label className="block text-gray-700">Vehical Number</label>
                    <input
                        {...register("VehiclesNumber", { required: "Vehicles Number is required" })}
                        className="w-full p-2 border rounded"
                    />
                    {errors.VehiclesNumber && (
                        <p className="text-red-500">{errors.VehiclesNumber.message}</p>
                    )}
                </div>

                <div>
                    <label className="block text-gray-700">Gate Pass No</label>
                    <input
                        {...register("gatePassNumber", { required: "GatePass Number is required" })}
                        className="w-full p-2 border rounded"
                    />
                    {errors.gatePassNumber && (
                        <p className="text-red-500">{errors.gatePassNumber.message}</p>
                    )}
                </div>

                <div>
                    <label className="block text-gray-700">No of Logs</label>
                    <input
                        {...register("no_of_log", { required: "No of log is required" })}
                        type="number"
                        className="w-full p-2 border rounded"
                    />
                    {errors.no_of_log && <p className="text-red-500">{errors.no_of_log.message}</p>}
                </div>

                <div>
                    <label className="block text-gray-700">No of Logs (Gate Pass)</label>
                    <input
                        {...register("no_of_logs_gatepass", {
                            required: "No of Logs (Gate Pass) is required",
                        })}
                        type="number"
                        className="w-full p-2 border rounded"
                    />
                    {errors.no_of_logs_gatepass && (
                        <p className="text-red-500">{errors.no_of_logs_gatepass.message}</p>
                    )}
                </div>

                <div>
                    <label className="block text-gray-700">Actual Received Pieces</label>
                    <input
                        {...register("actual_received_pieces", {
                            required: "Actual Received Pieces are required",
                        })}
                        type="number"
                        className="w-full p-2 border rounded"
                    />
                    {errors.actual_received_pieces && (
                        <p className="text-red-500">{errors.actual_received_pieces.message}</p>
                    )}
                </div>

                <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded">
                    Next
                </button>
            </form>
        </>
    );
};

export default AddLogStepOne;
