import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import UserManagementPage from "./pages/UserManagementPage";
import DashboardLayout from "./components/Layout/DashboardLayout";
import UserCreationForm from "./pages/UserCreationForm";
import AddLogForm from "./pages/AddLogForm";
import LogManagementPage from "./pages/ConsignmentManagementForm";
import SpeciesManagementPage from "./pages/SpeciesMaster/SpeciesManagement";
import AddSpeciesForm from "./pages/SpeciesMaster/AddSpecies";
import CategoryManagementPage from "./pages/CategoryMaster/CategoryManagement";
import AddCategory from "./pages/CategoryMaster/AddCategory";
import UpdateUser from "./pages/UpdateUser";
import UpdateCategory from "./pages/CategoryMaster/UpdateCategory";
import LocationManagementPage from "./pages/LocationMaster/LocationManagement";
import AddLocationPage from "./pages/LocationMaster/AddLocation";
import UpdateLocationPage from "./pages/LocationMaster/UpdateLocation";
import UpdateSpeciesPage from "./pages/SpeciesMaster/UpdateSpecies";
import AddEditConsignment from "./pages/LogsMaster/AddEditConsignment";
import ConsignmentManagementPage from "./pages/ConsignmentManagementForm";
import ConsignmentDetails from "./pages/ConsignmentDetails";
import MillConsignments from "./pages/MillManagement/MillConsignments";
import MillConsignmentLots from "./pages/MillManagement/MillConsignmentLots";
import MillScannedLots from "./pages/MillManagement/MillScannedLots";
import AddScannedConsignmentForm from "./pages/MillManagement/AddScannedConsignmentForm";
import SupplierManagementPage from "./pages/SupplierMaster/SupplierManagement";
import AddSupplier from "./pages/SupplierMaster/AddSupplier";
import AddScannedLogForm from "./pages/MillManagement/AddScannedLogForm";
import MillManagementPage from "./pages/MillManagement/MillMaster/MillManagementPage";
import AddMill from "./pages/MillManagement/MillMaster/AddMill";
import UpdateMill from "./pages/MillManagement/MillMaster/UpdateMill";

const App = () => {
    // Utility to check authentication
    const isAuthenticated = () => {
        const token = localStorage.getItem("authToken");
        return !!token; // Returns true if the token exists, false otherwise
    };

    // ProtectedRoute Component
    const ProtectedRoute = () => {
        return isAuthenticated() ? <Outlet /> : <Navigate to="/" replace />;
    };

    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />

                    {/* Authenticated Routes */}
                    <Route path="/" element={<DashboardLayout />}>
                        <Route element={<ProtectedRoute />}>
                            <Route path="dashboard" element={<Dashboard />} />
                            {/* Users */}
                            <Route path="userList" element={<UserManagementPage />} />
                            <Route path="createUser" element={<UserCreationForm />} />
                            <Route path="/users/update/:id" element={<UpdateUser />} />

                            {/* consignments */}
                            <Route
                                path="consignment-management"
                                element={<ConsignmentManagementPage />}
                            />
                            <Route path="/viewconsignment/:id" element={<ConsignmentDetails />} />
                            <Route path="/add-edit-consignment" element={<AddEditConsignment />} />
                            <Route
                                path="/add-edit-consignment/:id"
                                element={<AddEditConsignment />}
                            />
                            <Route path="/add-log" element={<AddLogForm />} />

                            {/* Species */}
                            <Route path="speciesManagement" element={<SpeciesManagementPage />} />
                            <Route path="addSpecies" element={<AddSpeciesForm />} />
                            <Route path="/species/update/:id" element={<UpdateSpeciesPage />} />

                            {/* Category */}
                            <Route path="categoryManagement" element={<CategoryManagementPage />} />
                            <Route path="addCategory" element={<AddCategory />} />
                            <Route path="/categories/update/:id" element={<UpdateCategory />} />

                            {/*Location*/}
                            <Route path="locationManagement" element={<LocationManagementPage />} />
                            <Route path="addLocation" element={<AddLocationPage />} />
                            <Route path="/locations/update/:id" element={<UpdateLocationPage />} />

                            {/*Mill*/}
                            <Route path="millManagement" element={<MillManagementPage />} />
                            <Route path="addMill" element={<AddMill />} />
                            <Route path="/mills/update/:id" element={<UpdateMill />} />

                            {/*Supplierdata*/}

                            {/* Lot Master
                        <Route path="/lotManagement" element={<LotManagementPage />} />
                        <Route path="/addLot" element={<AddEditLotPage />} />
                        <Route path="/editLot/:id" element={<AddEditLotPage />} /> */}

                            {/*Supplier Master*/}
                            <Route path="supplierManagement" element={<SupplierManagementPage />} />
                            <Route path="addSupplier" element={<AddSupplier />} />
                            <Route
                                path="/supplier/update/:id"
                                element={<AddSupplier isEdit={true} />}
                            />

                            {/* Mill Management Routes */}
                            <Route path="mill-consignments" element={<MillConsignments />} />
                            <Route
                                path="view-consignment-lots/:id"
                                element={<MillConsignmentLots />}
                            />
                            <Route
                                path="view-scanned-lots/:id/:lot_id"
                                element={<MillScannedLots />}
                            />

                            <Route path="/add-scanned-log" element={<AddScannedLogForm />} />

                            <Route
                                path="/add-scanned-consignment/:id/:lot_id"
                                element={<AddScannedConsignmentForm />}
                            />
                            <Route
                                path="/edit-scanned-consignment/:id/:lot_id/:millScannedConsignmentDataId"
                                element={<AddScannedConsignmentForm />}
                            />
                        </Route>
                    </Route>
                </Routes>
            </Router>
            <Toaster position="top-right" reverseOrder={false} />
        </>
    );
};

export default App;
