import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "../api/axios"; // Import the Axios instance
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const UserCreationForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = async (data) => {
        setLoading(true);
        setSuccessMessage("");
        setErrorMessage("");

        try {
            // Convert accessRights to array format
            const formattedData = {
                name: data.name.trim(),
                email: data.email.trim(),
                mobilenumber: data.mobileNo,
                username: data.username.trim(),
                password: data.password.trim(),
                device_id: data.device_id.trim(),
                status: data.status ?? 0,
                role: data.role,
                access_pages: data.accessRights || [],
            };

            // Make the API call
            const response = await axios.post("/users", formattedData);

            // Handle success
            setSuccessMessage("User created successfully!");
            reset(); // Reset the form
        } catch (err) {
            // Handle error
            setErrorMessage(err.response?.data?.message || "An unexpected error occurred.");
            console.error("API Error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Create User</h1>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" className="text-gray-600 text-xs font-bold" to="/userList">
                    Users
                </Link>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Create User
                </Link>
            </Breadcrumbs>

            {/* Success Message */}
            {successMessage && (
                <div className="bg-green-100 text-green-800 p-3 rounded mb-4">{successMessage}</div>
            )}

            {/* Error Message */}
            {errorMessage && (
                <div className="bg-red-100 text-red-800 p-3 rounded mb-4">{errorMessage}</div>
            )}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="bg-white shadow rounded-lg p-6 space-y-6"
            >
                {/* Name and Username */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-gray-600 mb-2">Name</label>
                        <input
                            {...register("name", { required: "Name is required" })}
                            type="text"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.name && (
                            <p className="text-red-600 text-sm">{errors.name.message}</p>
                        )}
                    </div>
                    <div>
                        <label className="block text-gray-600 mb-2">Username</label>
                        <input
                            {...register("username", { required: "Username is required" })}
                            type="text"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.username && (
                            <p className="text-red-600 text-sm">{errors.username.message}</p>
                        )}
                    </div>
                </div>

                {/* Mobile Number and Email */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-gray-600 mb-2">Mobile Number</label>
                        <input
                            {...register("mobileNo", {
                                required: "Mobile Number is required",
                                pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Mobile number must be 10 digits",
                                },
                            })}
                            type="text"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.mobileNo && (
                            <p className="text-red-600 text-sm">{errors.mobileNo.message}</p>
                        )}
                    </div>
                    <div>
                        <label className="block text-gray-600 mb-2">Device Id</label>
                        <input
                            {...register("device_id", { required: "Device id number is required" })}
                            type="text"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.device_id && (
                            <p className="text-red-600 text-sm">{errors.device_id.message}</p>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-gray-600 mb-2">Email</label>
                        <input
                            {...register("email", { required: "Email is required" })}
                            type="email"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.email && (
                            <p className="text-red-600 text-sm">{errors.email.message}</p>
                        )}
                    </div>

                    <div>
                        <label className="block text-gray-600 mb-2">Role</label>
                        <select
                            {...register("role", { required: "Role is required" })}
                            type="role"
                            className="w-full p-2 border rounded"
                        >
                            <option value="admin">Admin</option>
                            <option value="editor">Editor</option>
                            <option value="viewer">Viewer</option>
                        </select>
                        {errors.role && (
                            <p className="text-red-600 text-sm">{errors.role.message}</p>
                        )}
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Password */}
                    <div>
                        <label className="block text-gray-600 mb-2">Password</label>
                        <input
                            {...register("password", { required: "Password is required" })}
                            type="password"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.password && (
                            <p className="text-red-600 text-sm">{errors.password.message}</p>
                        )}
                    </div>

                    <div>
                        <label className="block text-gray-600 mb-2">Status</label>
                        <div className="flex items-center space-x-6">
                            <label className="flex items-center">
                                <input type="checkbox" value="1" className="mr-2" />
                                isActive
                            </label>
                        </div>
                    </div>
                </div>

                {/* Access Rights */}
                <div>
                    <label className="block text-gray-600 mb-2">Access Rights</label>
                    <div className="flex items-center space-x-6">
                        <label className="flex items-center">
                            <input
                                {...register("accessRights", {
                                    required: "At least one access right is required",
                                })}
                                type="checkbox"
                                value="Mill"
                                className="mr-2"
                            />
                            Mill
                        </label>
                        <label className="flex items-center">
                            <input
                                {...register("accessRights", {
                                    required: "At least one access right is required",
                                })}
                                type="checkbox"
                                value="Gate"
                                className="mr-2"
                            />
                            Gate
                        </label>
                        <label className="flex items-center">
                            <input
                                {...register("accessRights", {
                                    required: "At least one access right is required",
                                })}
                                type="checkbox"
                                value="Loading"
                                className="mr-2"
                            />
                            Loading
                        </label>
                        <label className="flex items-center">
                            <input
                                {...register("accessRights", {
                                    required: "At least one access right is required",
                                })}
                                type="checkbox"
                                value="Admin"
                                className="mr-2"
                            />
                            Admin
                        </label>
                        <label className="flex items-center">
                            <input
                                {...register("accessRights", {
                                    required: "At least one access right is required",
                                })}
                                type="checkbox"
                                value="Viewer"
                                className="mr-2"
                            />
                            Viewer
                        </label>
                    </div>
                    {errors.accessRights && (
                        <p className="text-red-600 text-sm">{errors.accessRights.message}</p>
                    )}
                </div>

                {/* Submit Button */}
                <div className="text-left">
                    <button
                        type="submit"
                        className={`bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-transform transform hover:scale-105 ${
                            loading ? "opacity-50 pointer-events-none" : ""
                        }`}
                        disabled={loading}
                    >
                        {loading ? "Creating..." : "Create User"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UserCreationForm;
