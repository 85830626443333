import React, { useState } from "react";
import {
    HomeIcon,
    CogIcon,
    UserGroupIcon,
    AcademicCapIcon,
    BriefcaseIcon,
    DocumentIcon,
    DocumentAddIcon,
    FolderIcon,
    CubeIcon,
    LogoutIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { InboxIcon } from "@heroicons/react/solid";
import logo from "../../assets/new_logo.jpg";
import { NavLink, useNavigate } from "react-router-dom";

const Sidebar = () => {
    const [masterOpen, setMasterOpen] = React.useState(false);
    const [woodCategoryOpen, setWoodCategoryOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("authToken");
        navigate("/");
    };

    return (
        <div className="fixed top-0 left-0 h-screen w-64 bg-gradient-to-b from-blue-900 to-blue-800 text-white shadow-lg">
            {/* Logo Section */}
            <div className="flex items-center justify-center h-20 border-b border-blue-700">
                <img src={logo} alt="Logo" className="w-16 h-16 rounded-full shadow-lg" />
            </div>

            {/* Navigation Menu */}
            <nav className="mt-4">
                <ul className="space-y-2">
                    {/* Dashboard */}
                    <li className="group">
                        <NavLink
                            to="/dashboard"
                            className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
                        >
                            <HomeIcon className="w-5 h-5 text-white mr-3" />
                            Dashboard
                        </NavLink>
                    </li>

                    <li className="group">
                        <NavLink
                            to="/userList"
                            className={({ isActive }) =>
                                `flex items-center px-4 py-3 text-sm font-medium rounded ${
                                    isActive ? "bg-blue-800" : "hover:bg-blue-700"
                                }`
                            }
                        >
                            <HomeIcon className="w-5 h-5 text-white mr-3" />
                            User Creation
                        </NavLink>
                    </li>
                    <li className="group">
                        <NavLink
                            to="/consignment-Management"
                            className={({ isActive }) =>
                                `flex items-center px-4 py-3 text-sm font-medium rounded ${
                                    isActive ? "bg-blue-800" : "hover:bg-blue-700"
                                }`
                            }
                        >
                            <InboxIcon className="w-5 h-5 text-white mr-3" />
                            Consignment
                        </NavLink>
                    </li>

                    <li className="group">
                        <NavLink
                            to="/mill-consignments"
                            className={({ isActive }) =>
                                `flex items-center px-4 py-3 text-sm font-medium rounded ${
                                    isActive ? "bg-blue-800" : "hover:bg-blue-700"
                                }`
                            }
                        >
                            <InboxIcon className="w-5 h-5 text-white mr-3" />
                            Mill Management
                        </NavLink>
                    </li>

                    <li className="group">
                        <NavLink
                            to="/speciesManagement"
                            className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
                        >
                            <DocumentAddIcon className="w-5 h-5 text-white mr-3" />
                            Species
                        </NavLink>
                    </li>

                    <li className="group">
                        <NavLink
                            to="/locationManagement"
                            className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
                        >
                            <DocumentIcon className="w-5 h-5 text-white mr-3" />
                            Location Master
                        </NavLink>
                    </li>

                    <li className="group">
                        <NavLink
                            to="/supplierManagement"
                            className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
                        >
                            <DocumentIcon className="w-5 h-5 text-white mr-3" />
                            Supplier Master
                        </NavLink>
                    </li>

                    <li className="group">
                        <NavLink
                            to="/millManagement"
                            className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
                        >
                            <DocumentIcon className="w-5 h-5 text-white mr-3" />
                            Mill Master
                        </NavLink>
                    </li>

                    <li className="group">
                        <NavLink
                            to="/dashboard"
                            className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
                        >
                            <DocumentIcon className="w-5 h-5 text-white mr-3" />
                            Product Master
                        </NavLink>
                    </li>
                    {/* <li className="group">
                        <NavLink
                            to="/lotManagement"
                            className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
                        >
                            <BriefcaseIcon className="w-5 h-5 text-white mr-3" />
                            Lot Master
                        </NavLink>
                    </li> */}

                    {/* Users */}
                    <li className="group">
                        <NavLink
                            to="/dashboard"
                            className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
                        >
                            <UserGroupIcon className="w-5 h-5 text-white mr-3" />
                            Reports
                        </NavLink>
                    </li>

                    {/* General */}
                    <li className="group">
                        <NavLink
                            to="/dashboard"
                            className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
                        >
                            <AcademicCapIcon className="w-5 h-5 text-white mr-3" />
                            General
                        </NavLink>
                    </li>

                    {/* Settings */}
                    <li className="group">
                        <NavLink
                            to="/dashboard"
                            className="flex items-center px-4 py-3 text-sm font-medium hover:bg-blue-700 hover:pl-6 transition-all duration-200 rounded"
                        >
                            <CogIcon className="w-5 h-5 text-white mr-3" />
                            Settings
                        </NavLink>
                    </li>

                    {/* Logout */}
                    <li className="group">
                        <button
                            onClick={handleLogout}
                            className="flex items-center w-full px-4 py-3 text-sm font-medium text-left hover:bg-red-600 hover:pl-6 transition-all duration-200 rounded"
                        >
                            <LogoutIcon className="w-5 h-5 text-white mr-3" />
                            Logout
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
