import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "../../api/axios";
import {
    Autocomplete,
    Breadcrumbs,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

const AddScannedLogStepOne = ({ onSubmit, initialData, setSelectedSpecieCategory }) => {
    const {
        register,
        handleSubmit,
        control,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: initialData || {},
    });

    const [selectedSpeciesType, setSelectedSpeciesType] = useState(""); // State for Species Type

    const [speciesloading, setspeciesLoading] = useState(false);
    const [speciesOptions, setSpeciesOptions] = useState([]);
    const [locationsloading, setlocationsLoading] = useState(false);
    const [locationOptions, setLocationOptions] = useState([]);
    const [lotsloading, setlotsLoading] = useState(false);
    const [lotOptions, setLotOptions] = useState([]);
    const [millsloading, setMillsLoading] = useState(false);
    const [millOptions, setMillOptions] = useState([]);
    const species_id = watch("species_id");

    // Function to fetch species based on a search term
    const fetchSpeciesOptions = async (searchKey = "", main_category_id = "") => {
        try {
            setspeciesLoading(true);
            const response = await axiosInstance.get("/speciesDropdown", {
                params: {
                    search: searchKey,
                    main_category_id: main_category_id || selectedSpeciesType,
                }, // Assuming the API accepts 'search' query parameter for filtering
            });
            const data = await response.data.species;
            const transformedData = data.map((item) => ({
                value: item.id,
                label: item.species_name,
                category: item.main_category_name?.toLowerCase(),
            }));
            setSpeciesOptions(transformedData);
        } catch (error) {
            console.error("Error fetching species options:", error);
        } finally {
            setspeciesLoading(false);
        }
    };

    const fetchLocationOptions = async (searchKey = "", main_category_id = "") => {
        try {
            setlocationsLoading(true);
            const response = await axiosInstance.get("/locationDropdown", {
                params: { search: searchKey, main_category_id }, // Assuming the API accepts 'search' query parameter for filtering
            });
            const data = await response.data.locations;
            const transformedData = data.map((item) => ({
                value: item.id,
                label: item.name + " ( " + item.code + " ) ",
            }));
            setLocationOptions(transformedData);
        } catch (error) {
            console.error("Error fetching location options:", error);
        } finally {
            setlocationsLoading(false);
        }
    };

    const fetchLotOptions = async (searchKey = "") => {
        const { species_id } = getValues();

        try {
            setlotsLoading(true);
            const response = await axiosInstance.get("/lotDropdown", {
                params: { search: searchKey, speciesId: species_id || selectedSpeciesType }, // Assuming the API accepts 'search' query parameter for filtering
            });
            const data = await response.data.lots;
            const transformedData = data.map((item) => ({
                value: item.lot_id,
                label: item.lot_id,
            }));
            setLotOptions(transformedData);
        } catch (error) {
            console.error("Error fetching lot options:", error);
        } finally {
            setlotsLoading(false);
        }
    };

    const fetchMillOptions = async (searchKey = "", specieId) => {
        try {
            setMillsLoading(true);
            const response = await axiosInstance.get("/millNoDropdown", {
                params: { search: searchKey, type: specieId || selectedSpeciesType }, // Assuming the API accepts 'search' query parameter for filtering
            });
            const data = await response.data.milldetails;
            const transformedData = data.map((item) => ({
                value: item.millDetailId,
                label: item.millNo,
            }));
            setMillOptions(transformedData);
        } catch (error) {
            console.error("Error fetching Mill options:", error);
        } finally {
            setMillsLoading(false);
        }
    };

    // Handle species type change
    const handleSpeciesTypeChange = (event) => {
        setSelectedSpeciesType(event.target.value);
        // Fetch species options based on the selected species type
        fetchSpeciesOptions("", event.target.value);

        fetchMillOptions("", event.target.value);
        setValue("species_id", "");
        setValue("lot_id", "");
        setValue("millDetailId", "");
    };


    // Fetch initial species data when the component mounts
    useEffect(() => {
        fetchSpeciesOptions();
        fetchLocationOptions();
        fetchLotOptions();
        fetchMillOptions();
    }, []);

    const handleFormSubmit = async () => {
        // First, validate and submit the form
        await handleSubmit(onSubmit)(); // Ensure form validation happens

        // Get the form values after successful submission
        const data = getValues();

        // Create the saved data object with all fields
        const savedData = {
            date: data.date,
            location:
                locationOptions.find((option) => option.value === data.location_id)?.label || "",
            species: speciesOptions.find((option) => option.value === data.species_id)?.label || "",
            speciesType: selectedSpeciesType === "1" ? "HardWood" : "Softwood (Pine)",
            lot: lotOptions.find((option) => option.value === data.lot_id)?.label || "",
            mill: millOptions.find((option) => option.value === data.millDetailId)?.label || "",
            vehiclesNumber: data.VehiclesNumber,
            gatePassNumber: data.gatePassNumber,
            noOfLog: data.no_of_log,
        };

        // Save the data to localStorage
        localStorage.setItem("globalMillData", JSON.stringify(savedData));
    };
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                    to="/mill-consignments"
                >
                    Mill Management
                </Link>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Add Scanned Log (Step One)
                </Link>
            </Breadcrumbs>

            <form onSubmit={handleFormSubmit} className="space-y-4">
                <h2 className="text-xl font-bold">Step 1: Log Enviorment Setup</h2>

                <div>
                    <label className="block text-gray-700">Date</label>
                    <Controller
                        name="date"
                        control={control}
                        rules={{ required: "Date is required" }}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                selected={field.value}
                                onChange={(date) => {
                                    field.onChange(date);
                                }}
                                dateFormat="yyyy-MM-dd"
                                className="w-full p-2 border rounded"
                                autoFocus // Ensure focus is set on the field automatically
                            />
                        )}
                    />
                    {errors.date && <p className="text-red-500">{errors.date.message}</p>}
                </div>

                <div>
                    <label className="block text-gray-700">Location</label>
                    <Controller
                        name="location_id"
                        control={control}
                        rules={{ required: "Location is required" }}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={locationOptions}
                                getOptionLabel={(option) => option.label || ""}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                loading={locationsloading}
                                onChange={(_, selectedOption) => {
                                    // Update the form state with the selected option's value
                                    field.onChange(selectedOption?.value || "");
                                }}
                                onInputChange={(_, newInputValue) => {
                                    // Trigger search when the input changes
                                    fetchLocationOptions(newInputValue?.split(" ")[0]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!errors.location_id}
                                        helperText={
                                            errors.location_id ? errors.location_id.message : ""
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {locationsloading && (
                                                        <span className="loading-spinner">
                                                            Loading...
                                                        </span>
                                                    )}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                disableClearable
                            />
                        )}
                    />
                </div>

                {/* Species Type Field */}
                <div className="mb-4">
                    <label className="block text-gray-700">Species Type</label>
                    <RadioGroup value={selectedSpeciesType} onChange={handleSpeciesTypeChange} row>
                        <FormControlLabel value="1" control={<Radio />} label="HardWood" />
                        <FormControlLabel value="2" control={<Radio />} label="Softwood (Pine)" />
                    </RadioGroup>
                </div>

                <div>
                    <label className="block text-gray-700">Species</label>
                    <Controller
                        name="species_id"
                        control={control}
                        rules={{ required: "Species is required" }}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={speciesOptions}
                                getOptionLabel={(option) => option.label || ""}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                loading={speciesloading}
                                onChange={(_, selectedOption) => {
                                    // Update the form state with the selected option's value
                                    field.onChange(selectedOption?.value || "");
                                    setSelectedSpecieCategory(selectedOption?.category || "");
                                    fetchLotOptions("", selectedOption?.value);
                                }}
                                onInputChange={(_, newInputValue) => {
                                    // Trigger search when the input changes
                                    fetchSpeciesOptions(newInputValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!errors.species_id}
                                        helperText={
                                            errors.species_id ? errors.species_id.message : ""
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {speciesloading && (
                                                        <span className="loading-spinner">
                                                            Loading...
                                                        </span>
                                                    )}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                disableClearable
                            />
                        )}
                    />
                </div>

                <div>
                    <label className="block text-gray-700">Lot {getValues("lot_id")}</label>
                    <Controller
                        name="lot_id"
                        control={control}
                        rules={{ required: "Lot is required" }}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={lotOptions}
                                getOptionLabel={(option) => option.label || ""}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                loading={lotsloading}
                                onChange={(_, selectedOption) => {
                                    // Update the form state with the selected option's value
                                    field.onChange(selectedOption?.value || "");
                                }}
                                onInputChange={(_, newInputValue) => {
                                    // Trigger search when the input changes
                                    fetchLotOptions(newInputValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!errors.lot_id}
                                        helperText={errors.lot_id ? errors.lot_id.message : ""}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {lotsloading && (
                                                        <span className="loading-spinner">
                                                            Loading...
                                                        </span>
                                                    )}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                disableClearable
                            />
                        )}
                    />
                </div>

                <div>
                    <label className="block text-gray-700">Mill</label>
                    <Controller
                        name="millDetailId"
                        control={control}
                        rules={{ required: "Mill assign is required" }}
                        render={({ field }) => (
                            <Autocomplete
                                posit
                                {...field}
                                options={millOptions}
                                getOptionLabel={(option) => option.label || ""}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                loading={millsloading}
                                onChange={(_, selectedOption) => {
                                    // Update the form state with the selected option's value
                                    field.onChange(selectedOption?.value || "");
                                }}
                                onInputChange={(_, newInputValue) => {
                                    // Trigger search when the input changes
                                    fetchMillOptions(newInputValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!errors.millDetailId}
                                        helperText={
                                            errors.millDetailId ? errors.millDetailId.message : ""
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {millsloading && (
                                                        <span className="loading-spinner">
                                                            Loading...
                                                        </span>
                                                    )}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                disableClearable
                            />
                        )}
                    />
                </div>

                <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded">
                    Next
                </button>
            </form>
        </>
    );
};

export default AddScannedLogStepOne;
