import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import Loader from "../../components/common/Loader";
import MessageAlert from "../../components/common/MessageAlert";

const SupplierManagementPage = () => {
    const [data, setData] = useState([]); // Supplier data
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(""); // Error state
    const [successMsg, setSuccessMsg] = useState(""); // Error state
    const [errorApiDelete, setErrorApiDelete] = useState(null); // error message for delete api

    // Fetch supplier on component mount
    useEffect(() => {
        const fetchSupplier = async () => {
            setLoading(true);
            setError("");
            try {
                const response = await axios.get("/supplier");
                setData(response.data.consignment); // Assuming API returns { supplier: [...] }
            } catch (err) {
                setError("Failed to fetch supplier. Please try again later.");
                console.error("API Error:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchSupplier();
    }, []);

    // Define table columns
    const columns = useMemo(
        () => [
            {
                Header: "Sr. No",
                // Render row index + 1 for serial number
                Cell: ({ row }) => row.index + 1,
            },
            { Header: "Supplier", accessor: "supplier" },
            { Header: "Code", accessor: "consignmentNo" },

            {
                Header: "Created Date",
                accessor: "created_at",
                Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : "-"),
            },
            {
                Header: "Date Modified",
                accessor: "updated_at",
                Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : "-"),
            },
            {
                Header: "Actions",
                Cell: ({ row }) => (
                    <div className="flex space-x-2">
                        <Link
                            to={`/supplier/update/${row.original.id}`}
                            className="text-green-600 hover:underline flex items-center"
                        >
                            <PencilIcon className="w-5 h-5 mr-1" />
                            Edit
                        </Link>
                        <button
                            onClick={() => handleDelete(row.original.id)}
                            className="text-red-600 hover:underline flex items-center"
                        >
                            <TrashIcon className="w-5 h-5 mr-1" />
                            Delete
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    // React Table hooks
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state,
        setGlobalFilter,
    } = useTable({ columns, data }, useGlobalFilter, usePagination);

    // Handle Delete Action
    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this supplier?")) {
            try {
                await axios.put(`/delete/supplier/${id}`);
                setData((prevData) => prevData.filter((supplier) => supplier.id !== id));
                setErrorApiDelete(null);
                setSuccessMsg("Supplier deleted successfully.");
            } catch (err) {
                if (err.response && err.response.data.message) {
                    setErrorApiDelete(err.response.data.message); // Show specific error from API
                } else {
                    setErrorApiDelete("Failed to delete supplier. Please try again.");
                }
            }
        }
    };

    return (
        <div className="p-6">
            {/* Page Header */}
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold text-gray-800">Supplier Management</h1>
                <Link
                    to="/addSupplier"
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
                >
                    <PlusIcon className="w-5 h-5 mr-2" />
                    Add Supplier
                </Link>
            </div>

            {/* Loading State */}
            {loading && <Loader />}

            {successMsg && (
                <MessageAlert
                    type="success"
                    message={successMsg}
                    onClose={() => setSuccessMsg("")}
                />
            )}

            {/* Error Message */}
            {errorApiDelete && (
                <MessageAlert
                    type="error"
                    message={errorApiDelete}
                    onClose={() => setErrorApiDelete("")}
                />
            )}

            {/* Error State */}
            {error && <MessageAlert type="error" message={error} onClose={() => setError("")} />}

            {/* Search Filter */}
            {!loading && !error && (
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Search supplier..."
                        value={state.globalFilter || ""}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
            )}

            {/* Supplier Table */}
            {!loading && !error && data.length > 0 && (
                <div className="overflow-x-auto bg-white shadow rounded-lg">
                    <table
                        {...getTableProps()}
                        className="min-w-full bg-white border border-gray-200"
                    >
                        <thead className="bg-gray-100">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className="px-4 py-2 text-left text-sm font-medium text-gray-600"
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="border-b last:border-none hover:bg-gray-50"
                                    >
                                        {row.cells.map((cell) => (
                                            <td
                                                {...cell.getCellProps()}
                                                className="px-4 py-2 text-sm text-gray-800"
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {/* No Supplier Found */}
            {!loading && !error && data.length === 0 && (
                <p>No supplier found. Please add some supplier.</p>
            )}
        </div>
    );
};

export default SupplierManagementPage;
