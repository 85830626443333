import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axiosInstance from "../../api/axios";
import { formatDate } from "../../utils/formatDate";
import { getValue } from "@testing-library/user-event/dist/utils";
import { useDebounce } from "use-debounce";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

const AddScannedLogStepTwo = ({ stepOneData, setStep, selectedSpecieCategory,refetchLocalStorageData }) => {
    const isHardwood = selectedSpecieCategory == "hardwood";
    const [loading, setLoading] = useState(false);
    const [cft, setCft] = useState(0);
    const [message, setMessage] = useState({});
    const [millData, setMillData] = useState({});
    const [searching, setSearching] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        clearErrors,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        setLoading(true);
        setMessage(null);

        try {
            const payload = {
                ...data,
                ...stepOneData,
                ifBroken: Number(getValues("ifBroken")),
                date: formatDate(stepOneData.date),
            };
            await axiosInstance.post(
                isHardwood
                    ? "app/mill/addScannedBarcodeDataHardwood"
                    : "app/mill/addScannedBarcodeDataSoftwood",
                payload
            );
            setMessage({ type: "success", text: "Submission successful!" });
            reset(); // Clear only Step 2 form fields
        } catch (err) {
            setMessage({
                type: "error",
                text: err.response?.data?.message || "Submission failed.",
            });
        } finally {
            setLoading(false);
        }
    };

    // Watch input values using react-hook-form's getValues method
    const log_no = watch("log_no");
    const length = watch("length");
    const barcodeData = watch("barcodeData");
    const dia = watch("dia");
    const ifPartialCutting = watch("ifPartialCutting");

    // Debounced values for log_no, length, and barcodeData
    const [debouncedLogNo] = useDebounce(log_no, 500); // Debounce log_no with 500ms delay
    const [debouncedLength] = useDebounce(length, 500); // Debounce length with 500ms delay
    const [debouncedBarcodeData] = useDebounce(barcodeData, 500); // Debounce barcodeData with 500ms delay

    // Debounced search for hardwood data (log_no, length)
    useEffect(() => {
        if (debouncedLogNo || debouncedLength) {
            handleSearchHardwoodData(debouncedLogNo, debouncedLength);
        }
    }, [debouncedLogNo, debouncedLength]);

    // Debounced search for softwood data (barcodeData)
    useEffect(() => {
        if (debouncedBarcodeData) {
            handleSearchSoftwoodData(debouncedBarcodeData);
        }
    }, [debouncedBarcodeData]);

    const handleSearchHardwoodData = async (log_no, length) => {
        // Validate log_no and length before making the API call
        if (!log_no) {
            setMessage({
                type: "error",
                text: "Log No is required.",
            });
            return;
        }

        try {
            setSearching(true);
            const response = await axiosInstance.get(
                `/app/mill/scanBarcodeHardwood?species_id=${stepOneData.species_id}&log_no=${log_no}&length=${length}`
            );

            // if (response.data.message === "Barcode scanned successfully.") {
            //     setMessage({
            //         type: "success",
            //         text: response.data.message,
            //     });

                setValue("dia", response.data.data.dia);
                setValue("length", response.data.data.length);
                setValue("hollow", response.data.data.hollow);
                setCft(response.data.data.cft);
                clearErrors();
            // } else {
            //     setMessage({
            //         type: "error",
            //         text: "An error occurred while scanning the barcode.",
            //     });
            // }
        } catch (error) {
            if (
                error.response?.status === 400 &&
                error.response?.data?.message === "Barcode already scanned."
            ) {
                setMessage({
                    type: "warning",
                    text: "This barcode has already been scanned.",
                });

                setValue("dia", error.response?.data?.data.dia);
                setValue("grade", error.response?.data?.data.grade);
                clearErrors();
            } else {
                setMessage({
                    type: "error",
                    text: error.response?.data?.message || "Submission failed.",
                });
            }
        } finally {
            setSearching(false);
        }
    };

    const handleSearchSoftwoodData = async (barcodeData) => {
        if (!barcodeData) {
            setMessage({
                type: "error",
                text: "QR code is required.",
            });
            return;
        }

        try {
            setSearching(true);
            const response = await axiosInstance.get(
                `/app/mill/scanBarcodeSoftwood?species_id=${stepOneData.species_id}&lot_id=${stepOneData.lot_id}&barcodeData=${barcodeData}`
            );

            // if (response.data.message === "Barcode scanned successfully.") {
                setMessage({
                    type: "success",
                    text: response.data.message,
                });

                setValue("length", response.data.data.length);
                setValue("dia", response.data.data.dia);
                setValue("grade", response.data.data.grade);
            // } else {
            //     setMessage({
            //         type: "error",
            //         text: "An error occurred while scanning the barcode.",
            //     });
            // }
        } catch (error) {
            if (
                error.response?.status === 400 &&
                error.response?.data?.message === "Barcode already scanned."
            ) {
                setMessage({
                    type: "warning",
                    text: "This barcode has already been scanned.",
                });

                setValue("length", error.response?.data?.data.length);
                setValue("grade", error.response?.data?.data.grade);
            } else {
                setMessage({
                    type: "error",
                    text: error.response?.data?.message || "Submission failed.",
                });
            }
        } finally {
            setSearching(false);
        }
    };

    useEffect(() => {
        if (!isHardwood && length && dia) {
            const cftCalculation = (Number(length) * Number(dia) * Number(dia) * 2.2072) / 10000;
            setCft(cftCalculation);
        } else {
            const cftCalculation = (Number(dia) * Number(dia) * Number(length) * 2.2014) / 10000;
            setCft(cftCalculation);
        }
    }, [length, dia]);

    useEffect(() => {
        const savedData = localStorage.getItem("globalMillData");
        if (savedData) {
            setMillData(JSON.parse(savedData));
        }
    }, [refetchLocalStorageData]);

    const partOptions = Array.from({ length: 10 }, (_, i) => String.fromCharCode(65 + i));

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                    to="/mill-consignments"
                >
                    Mill Management
                </Link>
                <div
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                    onClick={() => setStep(1)}
                >
                    Add Scanned Log (Step One)
                </div>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Add Scanned Log (Step Two)
                </Link>
            </Breadcrumbs>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <h2 className="text-xl font-bold">Step 2: Log Details</h2>

                <div className="mill-data-display border-2 p-3 mb-2">
                    <h2 className="text-xl font-bold mb-4">Mill Data Summary</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <strong>Date:</strong> {millData?.date}
                        </div>
                        <div>
                            <strong>Location:</strong> {millData?.location}
                        </div>
                        <div>
                            <strong>Species:</strong> {millData?.species}
                        </div>
                        <div>
                            <strong>Species Type:</strong> {millData?.speciesType}
                        </div>
                        <div>
                            <strong>Lot:</strong> {millData?.lot}
                        </div>
                        <div>
                            <strong>Mill:</strong> {millData?.mill}
                        </div>
                    </div>
                </div>

                {searching ? (
                    <p className="text-gray-900 text-sm font-medium">Searching...</p>
                ) : (
                    message && (
                        <p
                            className={`text-sm ${
                                message.type === "success" ? "text-green-600" : "text-red-600"
                            }`}
                        >
                            {message.text}
                        </p>
                    )
                )}

                <div className="flex gap-5">
                    {isHardwood ? (
                        <div>
                            <label className="block text-gray-700">Log No</label>
                            <input
                                {...register("log_no", { required: "Log No is required" })}
                                className="w-full p-2 border rounded"
                            />
                            {errors.log_no && (
                                <p className="text-red-500">{errors.log_no.message}</p>
                            )}
                        </div>
                    ) : (
                        <div>
                            <label className="block text-gray-700">QR Code</label>
                            <input
                                {...register("barcodeData", { required: "QR Code is required" })}
                                className="w-full p-2 border rounded"
                            />
                            {errors.barcodeData && (
                                <p className="text-red-500">{errors.barcodeData.message}</p>
                            )}
                        </div>
                    )}
                    {isHardwood && (
                        <>
                            <div>
                                <label className="block text-gray-700">Length</label>
                                <input
                                    {...register("length", { required: "Length is required" })}
                                    className="w-full p-2 border rounded"
                                />
                                {errors.length && (
                                    <p className="text-red-500">{errors.length.message}</p>
                                )}
                            </div>
                        </>
                    )}
                </div>

                {!isHardwood && (
                    <div>
                        <label className="block text-gray-700">Grade</label>
                        <input
                            {...register("grade", { required: "Grade is required" })}
                            className="w-full p-2 border rounded"
                        />
                        {errors.grade && <p className="text-red-500">{errors.grade.message}</p>}
                    </div>
                )}
                {!isHardwood && (
                    <>
                        <div>
                            <label className="block text-gray-700">Length</label>
                            <input
                                {...register("length", { required: "Length is required" })}
                                className="w-full p-2 border rounded"
                            />
                            {errors.length && (
                                <p className="text-red-500">{errors.length.message}</p>
                            )}
                        </div>
                        <div>
                            <label className="block text-gray-700">Dia</label>
                            <input
                                {...register("dia", { required: "Dia is required" })}
                                className="w-full p-2 border rounded"
                            />
                            {errors.dia && <p className="text-red-500">{errors.dia.message}</p>}
                        </div>
                        <div>
                            <label className="block text-gray-700">CFT</label>
                            <input disabled value={cft} className="w-full p-2 border rounded" />
                        </div>
                    </>
                )}

                {isHardwood && (
                    <div className="flex gap-5">
                        <div>
                            <label className="block text-gray-700">Girth</label>
                            <input
                                {...register("dia", { required: "Girth is required" })}
                                className="w-full p-2 border rounded"
                            />
                            {errors.dia && <p className="text-red-500">{errors.dia.message}</p>}
                        </div>

                        {/* Check Hollow Request Radio */}
                        <div>
                            <label className="block text-gray-700">Check Hollow Request</label>
                            <div>
                                <label className="mr-4">
                                    <input
                                        {...register("checkHollowRequest", { required: true })}
                                        type="radio"
                                        value={1}
                                        defaultChecked
                                        className="mr-2"
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        {...register("checkHollowRequest", { required: true })}
                                        type="radio"
                                        value={2}
                                        
                                        className="mr-2"
                                    />
                                    No
                                </label>
                            </div>
                        </div>

                        <div>
                            <label className="block text-gray-700">Hollow</label>
                            <input
                                {...register("hollow", { required: "Hollow is required" })}
                                className="w-full p-2 border rounded"
                            />
                            {errors.hollow && (
                                <p className="text-red-500">{errors.hollow.message}</p>
                            )}
                        </div>

                        <div>
                            <label className="block text-gray-700">CFT</label>
                            <input disabled value={cft} className="w-full p-2 border rounded" />
                        </div>
                    </div>
                )}

                <div>
                    <label className="block text-gray-700">Is Broken?</label>
                    <div>
                        <label>
                            <input
                                {...register("ifBroken")}
                                type="radio"
                                value={1}
                                className="mr-2"
                            />
                            Yes
                        </label>
                        <label className="ml-4">
                            <input
                                {...register("ifBroken")}
                                type="radio"
                                value={0}
                                defaultChecked
                                className="mr-2"
                            />
                            No
                        </label>
                    </div>
                </div>

                <div>
                    <label className="block text-gray-700">If Partial Cutting</label>
                    <div>
                        <label>
                            <input
                                {...register("ifPartialCutting")}
                                type="radio"
                                value={1}
                                className="mr-2"
                            />
                            Yes
                        </label>
                        <label className="ml-4">
                            <input
                                {...register("ifPartialCutting")}
                                type="radio"
                                value={0}
                                defaultChecked
                                className="mr-2"
                            />
                            No
                        </label>
                    </div>
                </div>

                {/* Conditional Dropdown for "Parts" */}
                {ifPartialCutting === "1" && (
                    <div className="mt-4">
                        <label className="block text-gray-700">Parts</label>
                        <select
                            {...register("part")}
                            className="block w-full px-4 py-2 border border-gray-300 rounded"
                        >
                            <option value="">Select a part</option>
                            {partOptions.map((part, index) => (
                                <option key={index} value={part}>
                                    {part}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                <div className="flex justify-between">
                    <button
                        type="button"
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                        onClick={() => setStep(1)} // Navigate back to Step 1
                    >
                        Back
                    </button>

                    <button
                        type="submit"
                        className={`bg-blue-600 text-white px-4 py-2 rounded ${
                            false ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={false}
                    >
                        {false ? "Submitting..." : "Submit"}
                    </button>
                </div>
            </form>
        </>
    );
};

export default AddScannedLogStepTwo;
