// components/Loader.js
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = ({ size = 20, className = "" }) => {
    return (
        <div className={`flex items-center justify-center ${className}`}>
            <CircularProgress size={size} />
        </div>
    );
};

export default Loader;
