import React from "react";
import { XIcon } from "@heroicons/react/outline";

const MessageAlert = ({ type = "success", message, onClose }) => {
  // Define styles based on the type of message
  const styles = {
    success: {
      container: "bg-green-100 border-green-400 text-green-800",
      icon: "text-green-800 hover:text-green-600",
    },
    error: {
      container: "bg-red-100 border-red-400 text-red-800",
      icon: "text-red-800 hover:text-red-600",
    },
    warning: {
      container: "bg-yellow-100 border-yellow-400 text-yellow-800",
      icon: "text-yellow-800 hover:text-yellow-600",
    },
    info: {
      container: "bg-blue-100 border-blue-400 text-blue-800",
      icon: "text-blue-800 hover:text-blue-600",
    },
  };

  const currentStyle = styles[type] || styles.success;

  return (
    <div
      className={`flex items-center justify-between border p-4 rounded-md mb-4 shadow ${currentStyle.container}`}
    >
      <span className="text-sm font-medium">{message}</span>
      <button
        onClick={onClose}
        className={`ml-4 focus:outline-none ${currentStyle.icon}`}
        aria-label="Close"
      >
        <XIcon className="w-5 h-5" />
      </button>
    </div>
  );
};

export default MessageAlert;
