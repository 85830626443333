import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import Loader from "../components/common/Loader";
import MessageAlert from "../components/common/MessageAlert";

const ConsignmentDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [consignment, setConsignment] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    // Fetch consignment details
    useEffect(() => {
        const fetchConsignment = async () => {
            setLoading(true);
            setError("");
            try {
                const response = await axios.get(`/viewConsignment/${id}`);
                setConsignment(response.data);
            } catch (err) {
                setError("Failed to fetch consignment details. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchConsignment();
    }, [id]);

    // Loader while fetching data
    if (loading) return <Loader className="h-60 bg-white" />;

    // Error message if fetching fails
    if (error) return <MessageAlert type="error" message={error} onClose={() => setError("")} />;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Consignment Details</h1>

            {consignment ? (
                <div className="bg-white p-6 shadow rounded">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Supplier */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">
                                Supplier:
                            </label>
                            <p className="text-gray-900">{consignment.supplier || "N/A"}</p>
                        </div>

                        {/* Consignment Number */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">
                                CODE:
                            </label>
                            <p className="text-gray-900">{consignment.consignmentNo || "N/A"}</p>
                        </div>

                        {/* Vessel */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">Vessel:</label>
                            <p className="text-gray-900">{consignment.vessel || "N/A"}</p>
                        </div>

                        {/* Created At */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">
                                Created At:
                            </label>
                            <p className="text-gray-900">
                                {new Date(consignment.created_at).toLocaleString() || "N/A"}
                            </p>
                        </div>

                        {/* Updated At */}
                        <div>
                            <label className="block text-gray-700 font-medium mb-1">
                                Updated At:
                            </label>
                            <p className="text-gray-900">
                                {new Date(consignment.updated_at).toLocaleString() || "N/A"}
                            </p>
                        </div>
                    </div>

                    {/* Table */}
                    {consignment?.consignmentdata?.length > 0 && (
                        <div className="overflow-y-auto mt-4" style={{ maxHeight: "400px" }}>
                            <table className="min-w-full bg-white border border-gray-200">
                                <thead className="sticky top-0 bg-blue-600 text-white">
                                    <tr>
                                        <th className="px-4 py-2 text-left text-sm font-medium">
                                            Row
                                        </th>
                                        <th className="px-4 py-2 text-left text-sm font-medium">
                                            Log No.
                                        </th>
                                        <th className="px-4 py-2 text-left text-sm font-medium">
                                            Length
                                        </th>
                                        <th className="px-4 py-2 text-left text-sm font-medium">
                                            Dia
                                        </th>
                                        <th className="px-4 py-2 text-left text-sm font-medium">
                                            Hollow
                                        </th>
                                        <th className="px-4 py-2 text-left text-sm font-medium">
                                            Volume
                                        </th>
                                        <th className="px-4 py-2 text-left text-sm font-medium">
                                            CFT
                                        </th>
                                        <th className="px-4 py-2 text-left text-sm font-medium">
                                            Lot No.
                                        </th>
                                        <th className="px-4 py-2 text-left text-sm font-medium">Grade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {consignment?.consignmentdata?.map((row, index) => (
                                        <tr
                                            key={row.consignmentdata_id}
                                            className="hover:bg-gray-50"
                                        >
                                            <td className="px-4 py-2 text-sm text-gray-800">
                                                {index + 1}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-800">
                                                {row.log_no}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-800">
                                                {row.length}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-800">
                                                {row.dia}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-800">
                                                {row.hollow || "-"}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-800">
                                                {row.volume}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-800">
                                                {row.cft}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-800">
                                                {row.lot_id}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-800">
                                        {row.grade}
                                    </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            ) : (
                <p className="text-red-600">No details available for this consignment.</p>
            )}

               {/* Back Button */}
          <div className="mt-6 text-right">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700"
            >
              Back
            </button>
          </div>

        </div>
    );
};

export default ConsignmentDetails;
