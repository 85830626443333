import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import axios from "../../api/axios";

const CategoryManagementPage = () => {
  const [data, setData] = useState([]); // Category data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(""); // Error state
  const [errorApiDelete, setErrorApiDelete] = useState(null); // error message for delete api

  // Fetch categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get("/categories/sub");
        setData(response.data.subcategories); // Assuming API returns { categories: [...] }
      } catch (err) {
        setError("Failed to fetch categories. Please try again later.");
        console.error("API Error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Define table columns
  const columns = useMemo(
    () => [
      {
        Header: "Sr. No",
        // Render row index + 1 for serial number
        Cell: ({ row }) => row.index + 1,
      },
      { Header: "Name", accessor: "subcategory_name" },
      { Header: "Category", accessor: "main_category_name" },
      {
        Header: "Created Date",
        accessor: "created_date",
        Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : "-",
      },
      {
        Header: "Date Modified",
        accessor: "modified_date",
        Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : "-",
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <Link
              to={`/categories/update/${row.original.subcategory_id}`}
              className="text-green-600 hover:underline flex items-center"
            >
              <PencilIcon className="w-5 h-5 mr-1" />
              Edit
            </Link>
            <button
              onClick={() => handleDelete(row.original.subcategory_id)}
              className="text-red-600 hover:underline flex items-center"
            >
              <TrashIcon className="w-5 h-5 mr-1" />
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  // React Table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, usePagination);

  // Handle Delete Action
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await axios.delete(`/categories/sub/${id}`);
        setData((prevData) => prevData.filter((category) => category.subcategory_id !== id));
        setErrorApiDelete(null);
      } catch (err) {
        if (err.response && err.response.data.message) {
          setErrorApiDelete(err.response.data.message); // Show specific error from API
        } else {
          setErrorApiDelete("Failed to delete category. Please try again.");
        }
      }
    }
  };

  return (
    <div className="p-6">
      {/* Page Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">Category Management</h1>
        <Link
          to="/addCategory"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
        >
          <PlusIcon className="w-5 h-5 mr-2" />
          Add Category
        </Link>
      </div>

      {/* Loading State */}
      {loading && <p>Loading categories...</p>}

       {/* Error Message */}
      {errorApiDelete && <p className="bg-red-100 text-red-800 p-3 rounded mb-4">{errorApiDelete}</p>}


      {/* Error State */}
      {error && (
        <div className="bg-red-100 text-red-800 p-3 rounded mb-4">{error}</div>
      )}

      {/* Search Filter */}
      {!loading && !error && (
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search categories..."
            value={state.globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      )}

      {/* Categories Table */}
      {!loading && !error && data.length > 0 && (
        <div className="overflow-x-auto bg-white shadow rounded-lg">
          <table
            {...getTableProps()}
            className="min-w-full bg-white border border-gray-200"
          >
            <thead className="bg-gray-100">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="px-4 py-2 text-left text-sm font-medium text-gray-600"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="border-b last:border-none hover:bg-gray-50"
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-4 py-2 text-sm text-gray-800"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {/* No Categories Found */}
      {!loading && !error && data.length === 0 && (
        <p>No categories found. Please add some categories.</p>
      )}

      
    </div>
  );
};

export default CategoryManagementPage;
