import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../api/axios";
import Loader from "../../components/common/Loader";
import MessageAlert from "../../components/common/MessageAlert";
import { Breadcrumbs } from "@mui/material";

const UpdateLocationPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    // Fetch location details
    useEffect(() => {
        const fetchLocation = async () => {
            setLoading(true);
            setError("");
            try {
                const response = await axios.get(`/locations/${id}`);
                const location = response.data.location;

                // Populate fields
                setValue("name", location.name);
                setValue("code", location.code);
                setValue("status", location.status ?? 1); // Default to Active
                setValue("description", location.description ?? ""); // Optional field
                setValue("radius", location.radius ?? 0); // Default to 0
            } catch (err) {
                setError("Failed to fetch location details. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchLocation();
    }, [id, setValue]);

    // Handle form submission
    const onSubmit = async (data) => {
        setError("");
        setLoadingSubmit(true);
        try {
            await axios.put(`/locations/${id}`, data);
            navigate("/locationManagement");
        } catch (err) {
            if (err.response && err.response.data.message) {
                setError(err.response.data.message); // Show server-side validation errors
            } else {
                setError("Failed to update location. Please try again.");
            }
        } finally {
            setLoadingSubmit(false);
        }
    };

    if (loading) return <Loader className="h-60 bg-white" />;
    if (error && !loading) return <p className="text-red-600">{error}</p>;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Update Location</h1>

            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                    to="/locationManagement"
                >
                    Location Management
                </Link>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Update Location
                </Link>
            </Breadcrumbs>

            {/* Error Message */}
            {error && <MessageAlert type="error" message={error} onClose={() => setError("")} />}

            {/* Update Location Form */}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-6 bg-white p-6 shadow rounded"
            >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Name Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">Name</label>
                        <input
                            {...register("name", { required: "Name is required" })}
                            type="text"
                            className="w-full p-2 border rounded"
                        />
                        {errors.name && (
                            <p className="text-red-600 text-sm">{errors.name.message}</p>
                        )}
                    </div>

                    {/* Code Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">Code</label>
                        <input
                            {...register("code", { required: "Code is required" })}
                            type="text"
                            className="w-full p-2 border rounded"
                        />
                        {errors.code && (
                            <p className="text-red-600 text-sm">{errors.code.message}</p>
                        )}
                    </div>

                    {/* Status Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">Status</label>
                        <select
                            {...register("status", { required: "Status is required" })}
                            className="w-full p-2 border rounded"
                        >
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                        </select>
                        {errors.status && (
                            <p className="text-red-600 text-sm">{errors.status.message}</p>
                        )}
                    </div>

                    {/* Description Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">Description</label>
                        <textarea
                            {...register("description")}
                            className="w-full p-2 border rounded"
                        ></textarea>
                        {errors.description && (
                            <p className="text-red-600 text-sm">{errors.description.message}</p>
                        )}
                    </div>

                    {/* Radius Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">Radius (meter)</label>
                        <input
                            {...register("radius", {
                                required: "Radius is required",
                                valueAsNumber: true,
                                min: { value: 0, message: "Radius cannot be negative" },
                            })}
                            type="number"
                            className="w-full p-2 border rounded"
                        />
                        {errors.radius && (
                            <p className="text-red-600 text-sm">{errors.radius.message}</p>
                        )}
                    </div>
                </div>

                {/* Submit Button */}
                <div className="text-right">
                    <button
                        type="submit"
                        className={`bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 ${
                            loadingSubmit ? "cursor-not-allowed opacity-50" : ""
                        }`}
                        disabled={loadingSubmit}
                    >
                        {loadingSubmit ? "Processing..." : "Update Location"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UpdateLocationPage;
