import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";
import MessageAlert from "../../components/common/MessageAlert";
import { Breadcrumbs } from "@mui/material";

const AddSupplierPage = ({ isEdit = false }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const onSubmit = async (data) => {
        setError(""); // Clear previous error
        setLoading(true); // Start loader

        try {
            if (isEdit) {
                await axios.put(`/supplier/${id}`, data);
            } else {
                await axios.post("/supplier", data);
            }
            navigate("/supplierManagement");
        } catch (err) {
            if (err.response && err.response.data.message) {
                setError(err.response.data.message); // Show server error message
            } else {
                setError("Failed to save supplier. Please try again.");
            }
        } finally {
            setLoading(false); // Stop loader
        }
    };

    useEffect(() => {
        if (isEdit && id) {
            const fetchSupplierData = async () => {
                try {
                    const response = await axios.get(`/supplier/${id}`);
                    const { supplier, consignmentNo } = response.data.consignment;
                    setValue("supplier", supplier);
                    setValue("consignmentNo", consignmentNo);
                } catch (err) {
                    setError("Failed to fetch supplier details. Please try again.");
                }
            };

            fetchSupplierData();
        }
    }, [isEdit, id, setValue]);

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">
                {isEdit ? "Edit" : "Add"} Supplier
            </h1>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                    to="/supplierManagement"
                >
                    Supplier Management
                </Link>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Manage Supplier
                </Link>
            </Breadcrumbs>

            {error && <MessageAlert type="error" message={error} onClose={() => setError("")} />}

            {/* Add Supplier Form */}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-6 bg-white p-6 shadow rounded"
            >
                {/* Two-column Layout */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Supplier Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">
                            Supplier <span className="text-red-600">*</span>
                        </label>
                        <input
                            {...register("supplier", { required: "Supplier is required" })}
                            type="text"
                            className="w-full p-2 border rounded"
                        />
                        {errors.supplier && (
                            <p className="text-red-600 text-sm">{errors.supplier.message}</p>
                        )}
                    </div>

                    {/* Consignment Number  / CODE Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">
                            CODE <span className="text-red-600">*</span>
                        </label>
                        <input
                            {...register("consignmentNo", {
                                required: "CODE is required",
                            })}
                            type="text"
                            className="w-full p-2 border rounded"
                        />
                        {errors.consignmentNo && (
                            <p className="text-red-600 text-sm">{errors.consignmentNo.message}</p>
                        )}
                    </div>
                </div>

                {/* Submit Button */}
                <div className="text-left">
                    <button
                        type="submit"
                        className={`bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 ${
                            loading ? "cursor-not-allowed opacity-50" : ""
                        }`}
                        disabled={loading}
                    >
                        {loading ? "Processing..." : "Add Supplier"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddSupplierPage;
