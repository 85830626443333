import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import MessageAlert from "../../components/common/MessageAlert";
import { Breadcrumbs } from "@mui/material";

const AddLocationPage = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        setError(""); // Clear previous error
        setLoading(true); // Start loader

        try {
            await axios.post("/locations", data);
            navigate("/locationManagement");
        } catch (err) {
            if (err.response && err.response.data.message) {
                setError(err.response.data.message); // Show server error message
            } else {
                setError("Failed to add location. Please try again.");
            }
        } finally {
            setLoading(false); // Stop loader
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Add Location</h1>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                    to="/locationManagement"
                >
                    Location Management
                </Link>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Add Location
                </Link>
            </Breadcrumbs>
         
            {error && <MessageAlert type="error" message={error} onClose={() => setError("")} />}

            {/* Add Location Form */}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-6 bg-white p-6 shadow rounded"
            >
                {/* Two-column Layout */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Name Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">
                            Name <span className="text-red-600">*</span>
                        </label>
                        <input
                            {...register("name", { required: "Name is required" })}
                            type="text"
                            className="w-full p-2 border rounded"
                        />
                        {errors.name && (
                            <p className="text-red-600 text-sm">{errors.name.message}</p>
                        )}
                    </div>

                    {/* Code Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">
                            Code <span className="text-red-600">*</span>
                        </label>
                        <input
                            {...register("code", { required: "Code is required" })}
                            type="text"
                            className="w-full p-2 border rounded"
                        />
                        {errors.code && (
                            <p className="text-red-600 text-sm">{errors.code.message}</p>
                        )}
                    </div>

                    {/* Status Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">
                            Status <span className="text-red-600">*</span>
                        </label>
                        <select
                            {...register("status", { required: "Status is required" })}
                            className="w-full p-2 border rounded"
                        >
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                        </select>
                        {errors.status && (
                            <p className="text-red-600 text-sm">{errors.status.message}</p>
                        )}
                    </div>

                    {/* Radius Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">
                            Radius (meter) <span className="text-red-600">*</span>
                        </label>
                        <input
                            {...register("radius", { required: "Radius is required" })}
                            type="number"
                            className="w-full p-2 border rounded"
                        />
                        {errors.radius && (
                            <p className="text-red-600 text-sm">{errors.radius.message}</p>
                        )}
                    </div>

                    {/* Description Field */}
                    <div className="md:col-span-2">
                        <label className="block text-gray-700 mb-2">Description</label>
                        <textarea
                            {...register("description")}
                            className="w-full p-2 border rounded"
                            rows="3"
                        ></textarea>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="text-left">
                    <button
                        type="submit"
                        className={`bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 ${
                            loading ? "cursor-not-allowed opacity-50" : ""
                        }`}
                        disabled={loading}
                    >
                        {loading ? "Processing..." : "Add Location"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddLocationPage;
