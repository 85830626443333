import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../../api/axios";
import Loader from "../../../components/common/Loader";
import MessageAlert from "../../../components/common/MessageAlert";
import { Breadcrumbs } from "@mui/material";

const UpdateMillPage = () => {
    const { id } = useParams(); // Get millNo from URL params
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    // Fetch mill details
    useEffect(() => {
        const fetchMill = async () => {
            setLoading(true);
            setError("");
            try {
                const response = await axios.get(`/millNo/${id}`);
                const mill = response.data.millDetail;

                // Populate fields
                setValue("millNo", mill.millNo);
                setValue("type", mill.type ?? "0"); // Default to "0" (Both)
            } catch (err) {
                setError("Failed to fetch mill details. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchMill();
    }, [id, setValue]);

    // Handle form submission
    const onSubmit = async (data) => {
        setError("");
        setLoadingSubmit(true);
        try {
            // Update mill using the millNo and type payload
            await axios.put(`/millNo/${id}`, { millNo: data.millNo, type: data.type });
            navigate("/millManagement");
        } catch (err) {
            if (err.response && err.response.data.message) {
                setError(err.response.data.message); // Show server-side validation errors
            } else {
                setError("Failed to update mill. Please try again.");
            }
        } finally {
            setLoadingSubmit(false);
        }
    };

    if (loading) return <Loader className="h-60 bg-white" />;
    if (error && !loading) return <p className="text-red-600">{error}</p>;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Update Mill</h1>

            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                    to="/millManagement"
                >
                    Mill Master
                </Link>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Update Mill
                </Link>
            </Breadcrumbs>


            {/* Error Message */}
            {error && <MessageAlert type="error" message={error} onClose={() => setError("")} />}

            {/* Update Mill Form */}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-6 bg-white p-6 shadow rounded"
            >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Mill Number Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">Mill No</label>
                        <input
                            {...register("millNo", { required: "Mill No is required" })}
                            type="text"
                            className="w-full p-2 border rounded"
                        />
                        {errors.millNo && (
                            <p className="text-red-600 text-sm">{errors.millNo.message}</p>
                        )}
                    </div>

                    {/* Type Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">Type</label>
                        <select
                            {...register("type", { required: "Type is required" })}
                            className="w-full p-2 border rounded"
                        >
                            <option value="0">Both</option>
                            <option value="1">Hardwood</option>
                            <option value="2">Softwood (Pine)</option>
                        </select>
                        {errors.type && (
                            <p className="text-red-600 text-sm">{errors.type.message}</p>
                        )}
                    </div>
                </div>

                {/* Submit Button */}
                <div className="text-right">
                    <button
                        type="submit"
                        className={`bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 ${
                            loadingSubmit ? "cursor-not-allowed opacity-50" : ""
                        }`}
                        disabled={loadingSubmit}
                    >
                        {loadingSubmit ? "Processing..." : "Update Mill"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UpdateMillPage;
