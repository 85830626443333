import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "../../api/axios";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const AddSpeciesForm = () => {
    const [mainCategories, setMainCategories] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false); // For success message

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    // Fetch Main Categories
    useEffect(() => {
        const fetchMainCategories = async () => {
            try {
                const response = await axios.get("/categories/main");
                const categories = response.data.mainCategories;
                setMainCategories(categories);
                // Set the first main category as default if it exists
                if (categories.length > 0) {
                    const defaultCategoryId = String(categories[0].id); // Use the first category's ID
                    setValue("mainCategory", defaultCategoryId); // Set default value in form
                }
            } catch (err) {
                setError("Failed to fetch main categories.");
            }
        };

        fetchMainCategories();
    }, []);

    const onSubmit = async (data) => {
        setError("");
        setSuccess(false); // Reset success message
        setSaving(true);
        try {
            await axios.post("/species", {
                name: data.name,
                code: data.code,
                main_category: data.mainCategory,
            });
            setSuccess(true); // Show success message
            reset(); // Clear the form after successful submission
        } catch (err) {
            if (err.response && err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Failed to add species. Please try again.");
            }
        } finally {
            setSaving(false);
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Add Species</h1>

            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                    to="/speciesManagement"
                >
                    Species Management
                </Link>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Add Species
                </Link>
            </Breadcrumbs>

            {error && <div className="bg-red-100 text-red-800 p-3 rounded mb-4">{error}</div>}

            {/* Success Message */}
            {success && (
                <div className="bg-green-100 text-green-800 p-3 rounded mb-4 flex justify-between items-center">
                    <span>Species added successfully!</span>
                    <button
                        onClick={() => setSuccess(false)}
                        className="text-green-800 font-bold hover:text-green-600"
                    >
                        ✕
                    </button>
                </div>
            )}

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-6 bg-white p-6 shadow rounded"
            >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Name Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">Name</label>
                        <input
                            {...register("name", { required: "Name is required." })}
                            type="text"
                            className={`w-full p-2 border rounded ${
                                errors.name ? "border-red-500" : "border-gray-300"
                            }`}
                            disabled={saving}
                        />
                        {errors.name && (
                            <p className="text-red-600 text-sm mt-1">{errors.name.message}</p>
                        )}
                    </div>

                    {/* Code Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">Code</label>
                        <input
                            {...register("code", { required: "Code is required." })}
                            type="text"
                            className={`w-full p-2 border rounded ${
                                errors.code ? "border-red-500" : "border-gray-300"
                            }`}
                            disabled={saving}
                        />
                        {errors.code && (
                            <p className="text-red-600 text-sm mt-1">{errors.code.message}</p>
                        )}
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Main Category Radio Buttons */}
                    <div>
                        <label className="block text-gray-700 mb-2">Main Category</label>
                        <div className="flex flex-wrap gap-4">
                            {mainCategories.map((category) => (
                                <div key={category.id} className="flex items-center">
                                    <input
                                        {...register("mainCategory", {
                                            required: "Main category must be selected.",
                                        })}
                                        type="radio"
                                        id={`main-category-${category.id}`}
                                        value={category.id}
                                        className="mr-2"
                                        disabled={saving}
                                    />
                                    <label htmlFor={`main-category-${category.id}`}>
                                        {category.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                        {errors.mainCategory && (
                            <p className="text-red-600 text-sm mt-1">
                                {errors.mainCategory.message}
                            </p>
                        )}
                    </div>
                </div>

                {/* Submit Button */}
                <div className="text-right">
                    <button
                        type="submit"
                        className={`bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 ${
                            saving ? "cursor-not-allowed opacity-50" : ""
                        }`}
                        disabled={saving}
                    >
                        {saving ? "Saving..." : "Add Species"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddSpeciesForm;
