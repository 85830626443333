import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import MessageAlert from "../../../components/common/MessageAlert";
import { Breadcrumbs } from "@mui/material";

const AddMill = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        setError(""); // Clear previous error
        setLoading(true); // Start loader

        try {
            await axios.post("/millNo", data);
            navigate("/millManagement");
        } catch (err) {
            if (err.response && err.response.data.message) {
                setError(err.response.data.message); // Show server error message
            } else {
                setError("Failed to add mill. Please try again.");
            }
        } finally {
            setLoading(false); // Stop loader
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Add Mill</h1>

            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                    to="/millManagement"
                >
                    Mill Master
                </Link>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Add Mill
                </Link>
            </Breadcrumbs>

            {error && <MessageAlert type="error" message={error} onClose={() => setError("")} />}

            {/* Add Mill Form */}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-6 bg-white p-6 shadow rounded"
            >
                {/* Two-column Layout */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Mill Number Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">
                            Mill No <span className="text-red-600">*</span>
                        </label>
                        <input
                            {...register("millNo", { required: "Mill No is required" })}
                            type="text"
                            className="w-full p-2 border rounded"
                        />
                        {errors.millNo && (
                            <p className="text-red-600 text-sm">{errors.millNo.message}</p>
                        )}
                    </div>

                    {/* Mill Type Field */}
                    <div>
                        <label className="block text-gray-700 mb-2">
                            Mill Type <span className="text-red-600">*</span>
                        </label>
                        <select
                            {...register("type", { required: "Mill Type is required" })}
                            className="w-full p-2 border rounded"
                        >
                            <option value={0}>Both</option>
                            <option value={1}>Hardwood</option>
                            <option value={2}>Softwood [Pine]</option>
                        </select>
                        {errors.type && (
                            <p className="text-red-600 text-sm">{errors.type.message}</p>
                        )}
                    </div>
                </div>

                {/* Submit Button */}
                <div className="text-left">
                    <button
                        type="submit"
                        className={`bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 ${
                            loading ? "cursor-not-allowed opacity-50" : ""
                        }`}
                        disabled={loading}
                    >
                        {loading ? "Processing..." : "Add Mill"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddMill;
