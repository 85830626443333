import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";
import Loader from "../../../components/common/Loader";
import MessageAlert from "../../../components/common/MessageAlert";

const MillManagementPage = () => {
  const [data, setData] = useState([]); // Mill data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(""); // Error state
  const [successMsg, setSuccessMsg] = useState(""); // Error state
  const [errorApiDelete, setErrorApiDelete] = useState(null); // error message for delete api

  // Fetch mills on component mount
  useEffect(() => {
    const fetchMills = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get("/millNo");
        setData(response.data.milldetails); // Assuming API returns { mills: [...] }
      } catch (err) {
        setError("Failed to fetch mills. Please try again later.");
        console.error("API Error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchMills();
  }, []);

  // Define table columns
  const columns = useMemo(
    () => [
      {
        Header: "Sr. No",
        // Render row index + 1 for serial number
        Cell: ({ row }) => row.index + 1,
      },
      { Header: "Mill No", accessor: "millNo" },
      {
        Header: "Mill Type",
        accessor: "type",
        Cell: ({ value }) => {
          switch (value) {
            case 0:
              return "Both";
            case 1:
              return "Hardwood";
            case 2:
              return "Softwood (Pine)";
            default:
              return "-"; // In case the value is undefined or doesn't match any condition
          }
        }
      },
      {
        Header: "Created Date",
        accessor: "created_at",
        Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : "-",
      },
      {
        Header: "Date Modified",
        accessor: "updated_at",
        Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : "-",
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <Link
              to={`/mills/update/${row.original.millDetailId}`}
              className="text-green-600 hover:underline flex items-center"
            >
              <PencilIcon className="w-5 h-5 mr-1" />
              Edit
            </Link>
            <button
              onClick={() => handleDelete(row.original.millDetailId)}
              className="text-red-600 hover:underline flex items-center"
            >
              <TrashIcon className="w-5 h-5 mr-1" />
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  // React Table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, usePagination);

  // Handle Delete Action
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this mill?")) {
      try {
        await axios.put(`/delete/millNo/${id}`);
        setData((prevData) => prevData.filter((mill) => mill.millDetailId !== id));
        setErrorApiDelete(null);
        setSuccessMsg("Mill deleted successfully.");
      } catch (err) {
        if (err.response && err.response.data.message) {
          setErrorApiDelete(err.response.data.message); // Show specific error from API
        } else {
          setErrorApiDelete("Failed to delete mill. Please try again.");
        }
      }
    }
  };

  return (
    <div className="p-6">
      {/* Page Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">Mill Management</h1>
        <Link
          to="/addMill"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
        >
          <PlusIcon className="w-5 h-5 mr-2" />
          Add Mill
        </Link>
      </div>

      {/* Loading State */}
      {loading && <Loader/>}

      {successMsg && (
        <MessageAlert
          type="success"
          message={successMsg}
          onClose={() => setSuccessMsg("")}
        />
      )}

       {/* Error Message */}
      {errorApiDelete && (
        <MessageAlert
          type="error"
          message={errorApiDelete}
          onClose={() => setErrorApiDelete("")}
        />
      )}

      {/* Error State */}
      {error && (
        <MessageAlert
          type="error"
          message={error}
          onClose={() => setError("")}
        />
      )}

      {/* Search Filter */}
      {!loading && !error && (
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search mills..."
            value={state.globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      )}

      {/* Mills Table */}
      {!loading && !error && data.length > 0 && (
        <div className="overflow-x-auto bg-white shadow rounded-lg">
          <table
            {...getTableProps()}
            className="min-w-full bg-white border border-gray-200"
          >
            <thead className="bg-gray-100">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="px-4 py-2 text-left text-sm font-medium text-gray-600"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="border-b last:border-none hover:bg-gray-50"
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-4 py-2 text-sm text-gray-800"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {/* No Mills Found */}
      {!loading && !error && data.length === 0 && (
        <p>No mills found. Please add some mills.</p>
      )}

      
    </div>
  );
};

export default MillManagementPage;
