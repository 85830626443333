import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { EyeIcon, PencilIcon, TrashIcon,PlusIcon } from "@heroicons/react/outline";
import axios from "../api/axios";
import { Link } from "react-router-dom";

const UserManagementPage = () => {
  const [data, setData] = useState([]); // User data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(""); // Error state

  // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get("/users");
        setData(response.data.users); // Assuming API returns { users: [...] }
      } catch (err) {
        setError("Failed to fetch users. Please try again later.");
        console.error("API Error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Define table columns
  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row }) => row.index + 1, // Calculate serial number
      },
      { Header: "Username", accessor: "username" },
      { Header: "Device Id", accessor: "device_id" },
      { Header: "Email", accessor: "email" },
      { Header: "Mobile No", accessor: "mobilenumber" },
      {
        Header: "Created Date",
        accessor: "created_date",
        Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : "-",
      },
      {
        Header: "Date Modified",
        accessor: "modified_date",
        Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : "-",
      },
  
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <span
            className={`px-2 py-1 text-xs font-semibold rounded ${
              value === 1
                ? "bg-green-100 text-green-800"
                : "bg-red-100 text-red-800"
            }`}
          >
            {value === 1 ? "Active" : "Inactive"}
          </span>
        ),
      },
      
      { Header: "Role", accessor: "role" },
      {
        Header: "Access Pages",
        accessor: "access_pages",
        Cell: ({ value }) => (
          <div className="flex flex-wrap gap-2">
            {JSON.parse(value).map((page, index) => (
              <span
                key={index}
                className="bg-gray-200 text-gray-800 text-xs px-2 py-1 rounded"
              >
                {page}
              </span>
            ))}
          </div>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              onClick={() => alert(`View user: ${row.original.username}`)}
              className="text-blue-600 hover:underline flex items-center"
            >
              <EyeIcon className="w-5 h-5 mr-1" />
              View
            </button>
            <Link
  to={`/users/update/${row.original.id}`}
  className="text-green-600 hover:underline flex items-center"
>
  <PencilIcon className="w-5 h-5 mr-1" />
  Edit
</Link>
            <button
              onClick={() => handleDelete(row.original.id)}
              className="text-red-600 hover:underline flex items-center"
            >
              <TrashIcon className="w-5 h-5 mr-1" />
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  // React Table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, usePagination);

  // Handle Delete Action
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        await axios.delete(`/users/${id}`); // Assuming DELETE /users/:id
        setData((prevData) => prevData.filter((user) => user.id !== id));
      } catch (err) {
        // alert("Failed to delete user. Please try again.");
        console.error("Delete Error:", err);
      }
    }
  };

  return (
    <div className="p-6">
      {/* Page Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">User Management</h1>
        <Link
          to="/createUser"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
        >
          <PlusIcon className="w-5 h-5 mr-2" />
          Add Uer
        </Link>
      </div>

      {/* Loading State */}
      {loading && <p>Loading users...</p>}

      {/* Error State */}
      {error && (
        <div className="bg-red-100 text-red-800 p-3 rounded mb-4">{error}</div>
      )}

      {/* Search Filter */}
      {!loading && !error && (
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search users..."
            value={state.globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      )}

      {/* Users Table */}
      {!loading && !error && data.length > 0 && (
        <div className="overflow-x-auto bg-white shadow rounded-lg">
          <table
            {...getTableProps()}
            className="min-w-full bg-white border border-gray-200"
          >
            <thead className="bg-gray-100">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="px-4 py-2 text-left text-sm font-medium text-gray-600"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="border-b last:border-none hover:bg-gray-50"
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-4 py-2 text-sm text-gray-800"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {/* No Users Found */}
      {!loading && !error && data.length === 0 && (
        <p>No users found. Please add some users.</p>
      )}
    </div>
  );
};

export default UserManagementPage;
