import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { EyeIcon, PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/common/Loader";
import MessageAlert from "../../components/common/MessageAlert";
import axios from "../../api/axios";

const MillScannedLots = () => {
    const { id, lot_id } = useParams();
    const [data, setData] = useState([]); // Consignment data
    const [loading, setLoading] = useState(false); // Loading state
    const [successMsg, setSuccessMsg] = useState(""); // Error state
    const [error, setError] = useState(""); // Error state
    const [consignmentInfo, setConsignmentInfo] = useState(""); // Error state
    const [filters, setFilters] = useState({
        fromDate: "",
        toDate: "",
        species: "",
        millNo: "",
        length: "",
        dia: "",
        grade: "",
        tallyStatus: "",
    });

    const fetchScannedConsignmentData = async () => {
        setLoading(true);
        setError("");
        try {
            const response = await axios.get(`/app/mill/getScannedConsignmentData?`, {
                params: { id, lot_id, ...filters },
            });
            setConsignmentInfo({
                consignmentNo: response.data.consignmentNo,
                supplier: response.data.supplier,
                vessel: response.data.vessel,
            });
            setData(response.data.consignmentdata); // Assuming API returns { consignments: [...] }
        } catch (err) {
            setData([]);
            setError("Failed to fetch scanned consignment data. Please try again later.");
            console.error("API Error:", err);
        } finally {
            setLoading(false);
        }
    };
    // Fetch consignments on component mount
    useEffect(() => {
        fetchScannedConsignmentData();
    }, [id]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    const handleClearFilters = () => {
        setFilters({
            fromDate: "",
            toDate: "",
            species: "",
            millNo: "",
            length: "",
            dia: "",
            grade: "",
            tallyStatus: "",
        });
    };

    // Define table columns
    const columns = useMemo(
        () => [
            {
                Header: "Sr. No",
                // Render row index + 1 for serial number
                Cell: ({ row }) => row.index + 1,
            },

            { Header: "QR code", accessor: "barcodeData" },
            {
                Header: "Date",
                accessor: "created_at",
                Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : "-"),
            },
            { Header: "Mill No.", accessor: "millNo", Cell: ({ value }) => value || "-" },
            { Header: "length", accessor: "length" },
            { Header: "SED (dia)", accessor: "dia" },
            {
                Header: "CFT",
                accessor: "cft",
                Cell: ({ value }) => <span className="font-bold">{value}</span> || "-",
            },
            { Header: "Grade", accessor: "grade", Cell: ({ value }) => value || "-" },
            {
                Header: "Tally Status",
                accessor: "tallyStatusDesciption",
                Cell: ({ value }) => value || "-",
            },
            {
                Header: "Date Modified",
                accessor: "updated_at",
                Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : "-"),
            },
            {
                Header: "Actions",
                Cell: ({ row }) => (
                    // Inside the Actions Cell
                    <div className="flex space-x-2">
                        <Link
                            to={`/edit-scanned-consignment/${id}/${lot_id}/${row.original.millScannedConsignmentDataId}`}
                            className="text-green-600 hover:underline flex items-center"
                        >
                            <PencilIcon className="w-5 h-5 mr-1" />
                            Edit
                        </Link>
                        <button
                            onClick={() => handleDelete(row.original.millScannedConsignmentDataId)}
                            className="text-red-600 hover:underline flex items-center"
                        >
                            <TrashIcon className="w-5 h-5 mr-1" />
                            Delete
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    // Handle Delete Action
    const handleDelete = async (id) => {
        if (
            window.confirm(
                `Are you sure you want to delete this scanned consignment  ID - (${id})?`
            )
        ) {
            try {
                await axios.delete(`/app/mill/DeleteScannedBarcodeData/${id}`);
                setData((prevData) =>
                    prevData.filter(
                        (consignment) => consignment.millScannedConsignmentDataId !== id
                    )
                );
                setError(null);
                setSuccessMsg("Scanned Consignment deleted successfully.");
            } catch (err) {
                if (err.response && err.response.data.message) {
                    setError(err.response.data.message); // Show specific error from API
                } else {
                    setError("Failed to delete Scanned consignment data. Please try again.");
                }
            }
        }
    };

    // React Table hooks
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state,
        setGlobalFilter,
    } = useTable({ columns, data }, useGlobalFilter, usePagination);

    return (
        <>
            <div className="p-6">
                {/* Page Header */}
                <div className="flex flex-col items-start mb-6">
                    <div className="w-full flex justify-between items-center mb-6">
                        <h1 className="text-2xl font-bold text-gray-800">
                            Scanned Consigment Data{" "}
                        </h1>
                        <Link
                            to={`/add-scanned-consignment/${id}/${lot_id}`}
                            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
                        >
                            <PlusIcon className="w-5 h-5 mr-2" />
                            Add QR code data
                        </Link>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : !error && data.length > 0 ? (
                        <div className="flex justify-start gap-2 text-sm font-bold text-gray-800">
                            <div className="border-r-2 border-r-black pr-2">
                                Consignment No :- ({consignmentInfo.consignmentNo})
                            </div>
                            <div className="border-r-2 border-r-black pr-2">
                                Supplier - {consignmentInfo.supplier}{" "}
                            </div>
                            <div className="border-r-2 border-r-black pr-2">
                                Vessel - {consignmentInfo.vessel}{" "}
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>

                {/* Form */}

                {successMsg && (
                    <MessageAlert
                        type="success"
                        message={successMsg}
                        onClose={() => setSuccessMsg("")}
                    />
                )}

                {/* Error State */}
                {error && (
                    <MessageAlert type="error" message={error} onClose={() => setError("")} />
                )}

                {/* Filters Section */}
                <div className="bg-white p-4 mb-4 shadow rounded">
                    <h2 className="text-lg font-semibold mb-4">Filters</h2>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        {/* Start Date */}
                        <div>
                            <label
                                htmlFor="fromDate"
                                className="block text-sm font-medium text-gray-700"
                            >
                                From Date
                            </label>
                            <input
                                id="fromDate"
                                type="date"
                                name="fromDate"
                                value={filters.fromDate}
                                onChange={handleFilterChange}
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>

                        {/* End Date */}
                        <div>
                            <label
                                htmlFor="toDate"
                                className="block text-sm font-medium text-gray-700"
                            >
                                To Date
                            </label>
                            <input
                                id="toDate"
                                type="date"
                                name="toDate"
                                value={filters.toDate}
                                onChange={handleFilterChange}
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>

                        {/* Other Filters */}
                        <div>
                            <label
                                htmlFor="species"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Species
                            </label>
                            <input
                                id="species"
                                type="text"
                                name="species"
                                value={filters.species}
                                onChange={handleFilterChange}
                                placeholder="enter species"
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="millNo"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Mill No.
                            </label>
                            <input
                                id="millNo"
                                type="text"
                                name="millNo"
                                value={filters.millNo}
                                onChange={handleFilterChange}
                                placeholder="e.g., 12345"
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="length"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Length
                            </label>
                            <input
                                id="length"
                                type="number"
                                name="length"
                                value={filters.length}
                                onChange={handleFilterChange}
                                placeholder="e.g., 12.5"
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="dia"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Diameter (SED)
                            </label>
                            <input
                                id="dia"
                                type="number"
                                name="dia"
                                value={filters.dia}
                                onChange={handleFilterChange}
                                placeholder="e.g., 8"
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="grade"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Grade
                            </label>
                            <input
                                id="grade"
                                type="text"
                                name="grade"
                                value={filters.grade}
                                onChange={handleFilterChange}
                                placeholder="e.g., A"
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="tallyStatus"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Tally Status
                            </label>
                            <select
                                id="tallyStatus"
                                name="tallyStatus"
                                value={filters.tallyStatus}
                                onChange={handleFilterChange}
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            >
                                <option value="">All</option>
                                <option value={1}>Tally</option>
                                <option value={2}>Non-Tally</option>
                            </select>
                        </div>
                    </div>

                    <div className="mt-4 flex justify-end gap-4">
                        <button
                            onClick={fetchScannedConsignmentData}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Apply Filters
                        </button>
                        <button
                            onClick={handleClearFilters}
                            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                        >
                            Clear Filters
                        </button>
                    </div>
                </div>

                {/* Consignments Table */}
                {loading ? (
                    <Loader />
                ) : (
                    !loading &&
                    !error &&
                    data.length > 0 && (
                        <div className="overflow-x-auto bg-white shadow rounded-lg">
                            <table
                                {...getTableProps()}
                                className="min-w-full bg-white border border-gray-200"
                            >
                                <thead className="bg-gray-100">
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    {...column.getHeaderProps()}
                                                    className="px-4 py-2 text-left text-sm font-medium text-gray-600"
                                                >
                                                    {column.render("Header")}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr
                                                {...row.getRowProps()}
                                                className="border-b last:border-none hover:bg-gray-50"
                                            >
                                                {row.cells.map((cell) => (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="px-4 py-2 text-sm text-gray-800"
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )
                )}

                {/* No Consignments Found */}
                {!loading && !error && data.length === 0 && (
                    <p>No scanned barcode data found. Please add scanned barcode data.</p>
                )}
            </div>
        </>
    );
};

export default MillScannedLots;
