import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../api/axios";

const AddSubcategoryPage = () => {
  const [mainCategories, setMainCategories] = useState([]);
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false); // For success message

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm();

  // Fetch Main Categories
  useEffect(() => {
    const fetchMainCategories = async () => {
      try {
        const response = await axios.get("/categories/main");
        const categories = response.data.mainCategories;
        setMainCategories(categories);

        // Set the first category as the default selection
        if (categories.length > 0) {
          setValue("mainCategory", String(categories[0].id)); // Default value
        }
      } catch (err) {
        setError("Failed to fetch main categories.");
      }
    };

    fetchMainCategories();
  }, [setValue]);

  const onSubmit = async (data) => {
    setError("");
    setSuccess(false);
    setSaving(true);
    try {
      await axios.post("/categories/sub", {
        name: data.name,
        parent_id: data.mainCategory,
      });
      setSuccess(true);
      reset();
      // navigate("/categoryManagement");
    } catch (err) {
      if (err.response && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError("Failed to add subcategory. Please try again.");
      }
    } finally {
      
      setSaving(false);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Add Subcategory</h1>

      {error && <div className="bg-red-100 text-red-800 p-3 rounded mb-4">{error}</div>}

      {/* Success Message */}
      {success && (
        <div className="bg-green-100 text-green-800 p-3 rounded mb-4 flex justify-between items-center">
          <span>SubCategory added successfully!</span>
          <button
            onClick={() => setSuccess(false)}
            className="text-green-800 font-bold hover:text-green-600"
          >
            ✕
          </button>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 bg-white p-6 shadow rounded">
        {/* Main Category Radio Buttons */}
        <div>
          <label className="block text-gray-700 mb-2">Main Category</label>
          <div className="flex flex-wrap gap-4">
            {mainCategories.map((category) => (
              <div key={category.id} className="flex items-center">
                <input
                  {...register("mainCategory", {
                    required: "Main category must be selected.",
                  })}
                  type="radio"
                  id={`main-category-${category.id}`}
                  value={category.id}
                  className="mr-2"
                  disabled={saving}
                />
                <label htmlFor={`main-category-${category.id}`}>{category.name}</label>
              </div>
            ))}
          </div>
          {errors.mainCategory && (
            <p className="text-red-600 text-sm mt-1">{errors.mainCategory.message}</p>
          )}
        </div>

        {/* Subcategory Name Field */}
        <div>
          <label className="block text-gray-700 mb-2">Subcategory Name</label>
          <input
            {...register("name", { required: "Subcategory name is required." })}
            type="text"
            className={`w-full p-2 border rounded ${
              errors.name ? "border-red-500" : "border-gray-300"
            }`}
            disabled={saving}
          />
          {errors.name && <p className="text-red-600 text-sm mt-1">{errors.name.message}</p>}
        </div>

        {/* Submit Button */}
        <div className="text-right">
          <button
            type="submit"
            className={`bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 ${
              saving ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={saving}
          >
            {saving ? "Saving..." : "Add Subcategory"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddSubcategoryPage;
