// Utility function to format date as YYYY-MM-DD
export const formatDate = (date) => {
    if (!date) return ""; // Handle empty date

    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = d.getDate().toString().padStart(2, "0");

    // Format date as YYYY-MM-DD
    return `${year}-${month}-${day}`;
};
