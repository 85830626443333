import React, { useState, useEffect, useMemo } from "react";
import { useTable } from "react-table";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import MessageAlert from "../../components/common/MessageAlert";

const SpeciesManagementPage = () => {
  const [species, setSpecies] = useState([]);
    const [successMsg, setSuccessMsg] = useState(""); // Error state
  const [error, setError] = useState("");

  // Fetch Species
  useEffect(() => {
    const fetchSpecies = async () => {
      try {
        const response = await axios.get("/species");
        setSpecies(response.data.species);
      } catch (err) {
        setError("Failed to fetch species. Please try again.");
      }
    };

    fetchSpecies();
  }, []);

  // Define table columns
  const columns = useMemo(
    () => [
      {
        Header: "Sr. No",
        Cell: ({ row }) => row.index + 1, // Serial number
      },
      {
        Header: "Name",
        accessor: "species_name",
      },
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Main Category",
        accessor: "main_category_name",
      },
      {
        Header: "Created Date",
        accessor: "created_date",
        Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : "-",
      },
      {
        Header: "Date Modified",
        accessor: "modified_date",
        Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : "-",
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
           
            <Link
              to={`/species/update/${row.original.id}`}
              className="text-green-600 hover:underline flex items-center"
            >
              <PencilIcon className="w-5 h-5 mr-1" />
              Edit
            </Link>
            <button
              onClick={() => handleDelete(row.original.id)}
              className="text-red-600 hover:underline flex items-center"
            >
              <TrashIcon className="w-5 h-5 mr-1" />
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  // Delete Handler
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this species?")) {
      try {
        await axios.delete(`/species/${id}`);
        setSuccessMsg("Location deleted successfully.");
        setSpecies((prev) => prev.filter((item) => item.id !== id));
      } catch (err) {
        console.log("error", err)
        // alert("Failed to delete species. Please try again.");
      }
    }
  };

  // React Table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: species });

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Species Management</h1>

      {/* Error Message */}
        {/* Error State */}
        {error && (
        <MessageAlert
          type="error"
          message={error}
          onClose={() => setError("")}
        />
      )}

      {successMsg && (
        <MessageAlert
          type="success"
          message={successMsg}
          onClose={() => setSuccessMsg("")}
        />
      )}

      {/* Add Species Button */}
      <div className="mb-4 text-right">
        <Link
          to="/addSpecies"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Add Species
        </Link>
      </div>

      {/* Species Table */}
      <div className="overflow-x-auto bg-white shadow rounded-lg">
        <table {...getTableProps()} className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-100">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="px-4 py-2 text-left text-sm font-medium text-gray-600"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="hover:bg-gray-50">
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="px-4 py-2 text-sm text-gray-800"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SpeciesManagementPage;
