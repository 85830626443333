import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/common/Loader";
import MessageAlert from "../../components/common/MessageAlert";
import axios from "../../api/axios";

const MillConsignmentLots = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]); // Consignment data
    const [loading, setLoading] = useState(false); // Loading state
    const [successMsg, setSuccessMsg] = useState(""); // Error state
    const [error, setError] = useState(""); // Error state
    const [selectedLotId, setSelectedLotId] = useState(""); // Error state
    const [consignmentInfo, setConsignmentInfo] = useState(""); // Error state

    const fetchConsignmentLots = async () => {
        setLoading(true);
        setError("");
        try {
            const response = await axios.get(`/app/mill/getConsignmentData/${id}`);
            setConsignmentInfo({
                consignmentNo: response.data.consignmentNo,
                supplier: response.data.supplier,
                vessel: response.data.vessel,
            });
            setData(response.data.consignmentdata); // Assuming API returns { consignments: [...] }
        } catch (err) {
            setData([]);
            setError("Failed to fetch consignments lots. Please try again later.");
            console.error("API Error:", err);
        } finally {
            setLoading(false);
        }
    };
    // Fetch consignments on component mount
    useEffect(() => {
        fetchConsignmentLots();
    }, [id]);

    // Define table columns
    const columns = useMemo(
        () => [
            {
                Header: "Sr. No",
                // Render row index + 1 for serial number
                Cell: ({ row }) => row.index + 1,
            },
            { Header: "Lot No", accessor: "lot_id" },

            { Header: "Total Scanned", accessor: "scanned_count" },
            { Header: "Total Tally", accessor: "tally_count" },
            { Header: "Total Non Tally", accessor: "non_tally_count" },
            { Header: "Total Lot Records", accessor: "lot_total_count" },
            {
                Header: "Created Date",
                accessor: "created_at",
                Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : "-"),
            },
            {
                Header: "Date Modified",
                accessor: "updated_at",
                Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : "-"),
            },
            { Header: "Assigned Mill", accessor: "millNo", Cell: ({ value }) => value || "-" },
        ],
        []
    );

    const handleActionClick = (lot) => {
        navigate(`/view-scanned-lots/${lot.consignmentId}/${lot.lot_id}`);
    };

    // React Table hooks
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state,
        setGlobalFilter,
    } = useTable({ columns, data }, useGlobalFilter, usePagination);

    return (
        <>
            <div className="p-6">
                {/* Page Header */}
                <div className="flex flex-col items-start mb-6">
                    <h1 className="text-2xl font-bold text-gray-800">Consignments Lots List </h1>
                    {!loading && !error && (
                        <div className="flex justify-start gap-2 text-sm font-bold text-gray-800">
                            <div className="border-r-2 border-r-black pr-2">
                                CODE :- ({consignmentInfo.consignmentNo})
                            </div>
                            <div className="border-r-2 border-r-black pr-2">
                                Supplier - {consignmentInfo.supplier}{" "}
                            </div>
                            <div className="border-r-2 border-r-black pr-2">
                                Vessel - {consignmentInfo.vessel}{" "}
                            </div>
                        </div>
                    )}
                </div>

                {/* Loading State */}
                {loading && <Loader />}

                {successMsg && (
                    <MessageAlert
                        type="success"
                        message={successMsg}
                        onClose={() => setSuccessMsg("")}
                    />
                )}

                {/* Error State */}
                {error && (
                    <MessageAlert type="error" message={error} onClose={() => setError("")} />
                )}

                {/* Search Filter */}
                {!loading && !error && (
                    <div className="mb-4">
                        <input
                            type="text"
                            placeholder="Search lots..."
                            value={state.globalFilter || ""}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                )}

                {/* Consignments Table */}
                {!loading && !error && data.length > 0 && (
                    <div className="overflow-x-auto bg-white shadow rounded-lg">
                        <table
                            {...getTableProps()}
                            className="min-w-full bg-white border border-gray-200"
                        >
                            <thead className="bg-gray-100">
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                {...column.getHeaderProps()}
                                                className="px-4 py-2 text-left text-sm font-medium text-gray-600"
                                            >
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            className="border-b last:border-none hover:bg-gray-50"
                                            onClick={() => handleActionClick(row.original)}
                                        >
                                            {row.cells.map((cell) => (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="px-4 py-2 text-sm text-gray-800"
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}

                {/* No Consignments Found */}
                {!loading && !error && data.length === 0 && (
                    <p>No lots found. Please add some lots.</p>
                )}
            </div>
        </>
    );
};

export default MillConsignmentLots;
