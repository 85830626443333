import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios"; // Import your Axios instance
import logo from "../assets/new_logo.jpg"; // Path to your logo

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordToggle = () => setShowPassword((prev) => !prev);

  const validateForm = () => {
    if (!username) {
      setError("Username is required");
      return false;
    }
   
    if (!password) {
      setError("Password is required");
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axios.post("/auth/login", { username: username, password });
        setLoading(false);

        // Save token to localStorage or context
        const { token } = response.data;
        localStorage.setItem("authToken", token);

        // Navigate to dashboard
        navigate("/dashboard");
      } catch (err) {
        setLoading(false);
        setError(
          err.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "linear-gradient(135deg, #2B3990, #1EAD5A)",
      }}
    >
      <Paper
        elevation={12}
        sx={{
          padding: 4,
          width: { xs: "90%", sm: "400px" },
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        {/* Logo Section */}
        <Box sx={{ mb: 3 }}>
          <img src={logo} alt="Logo" style={{ maxWidth: "120px" }} />
        </Box>

        {/* Form Title */}
        <Typography
          variant="h4"
          color="primary"
          textAlign="center"
          gutterBottom
        >
          Login
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          textAlign="center"
          marginBottom={3}
        >
          Please enter your credentials
        </Typography>

        {/* Display Error Message */}
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

        {/* Login Form */}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            fullWidth
            variant="outlined"
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="Password"
            fullWidth
            variant="outlined"
            margin="normal"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordToggle} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3, py: 1 }}
            disabled={loading}
          >
            {loading ? "Logging in..." : "Login"}
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default LoginForm;
