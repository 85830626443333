import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../api/axios";
import MessageAlert from "../components/common/MessageAlert";

const UpdateUserPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(""); //This is for displaying error messages for failed prefilled data api then display nothing
    const [error, setError] = useState("");
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    // Fetch user data on component mount
    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            setErrorMsg("");
            try {
                const response = await axios.get(`/users/${id}`);
                const fetchedUser = response.data.user;

                reset({
                    name: fetchedUser.name,
                    email: fetchedUser.email,
                    mobilenumber: fetchedUser.mobilenumber,
                    username: fetchedUser.username,
                    password: "",
                    device_id: fetchedUser.device_id,
                    status: fetchedUser.status,
                    role: fetchedUser.role,
                    access_pages: JSON.parse(fetchedUser.access_pages || "[]"),
                });
                console.log(fetchedUser.access_pages || "[]");
            } catch (err) {
                console.error("Fetch Error:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [id, reset]);

    // Submit form
    const onSubmit = async (data) => {
        try {
            await axios.put(`/users/${id}`, {
                ...data,
                // access_pages: JSON.stringify(data.access_pages || []),
            });
            navigate("/userList");
        } catch (err) {
            setError("Failed to update user. Please try again.");
            console.error("Update Error:", err);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (errorMsg) return <p className="text-red-600">{errorMsg}</p>;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Update User</h1>

            {error && <MessageAlert type="error" message={error} onClose={() => setError("")} />}

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-6 bg-white p-6 shadow rounded"
            >
                {/* Grid for Two Columns */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Name */}
                    <div>
                        <label className="block text-gray-700 mb-2">Name</label>
                        <input
                            {...register("name", { required: "Name is required" })}
                            type="text"
                            className="w-full p-2 border rounded"
                        />
                        {errors.name && (
                            <p className="text-red-600 text-sm">{errors.name.message}</p>
                        )}
                    </div>

                    {/* Email */}
                    <div>
                        <label className="block text-gray-700 mb-2">Email</label>
                        <input
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: "Invalid email format",
                                },
                            })}
                            type="email"
                            className="w-full p-2 border rounded"
                        />
                        {errors.email && (
                            <p className="text-red-600 text-sm">{errors.email.message}</p>
                        )}
                    </div>

                    {/* Mobile Number */}
                    <div>
                        <label className="block text-gray-700 mb-2">Mobile Number</label>
                        <input
                            {...register("mobilenumber", {
                                required: "Mobile Number is required",
                                pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Mobile number must be 10 digits",
                                },
                            })}
                            type="text"
                            className="w-full p-2 border rounded"
                        />
                        {errors.mobilenumber && (
                            <p className="text-red-600 text-sm">{errors.mobilenumber.message}</p>
                        )}
                    </div>

                    {/* Username */}
                    <div>
                        <label className="block text-gray-700 mb-2">Username</label>
                        <input
                            {...register("username")}
                            type="text"
                            className="w-full p-2 border rounded"
                            disabled
                        />
                    </div>

                    {/* Password */}
                    <div>
                        <label className="block text-gray-700 mb-2">Password (optional)</label>
                        <input
                            {...register("password", {
                                minLength: {
                                    value: 6,
                                    message: "Password must be at least 6 characters long",
                                },
                            })}
                            type="password"
                            className="w-full p-2 border rounded"
                        />
                        {errors.password && (
                            <p className="text-red-600 text-sm">{errors.password.message}</p>
                        )}
                    </div>

                    {/* Device ID */}
                    <div>
                        <label className="block text-gray-700 mb-2">Device ID</label>
                        <input
                            {...register("device_id")}
                            type="text"
                            className="w-full p-2 border rounded"
                        />
                    </div>

                    {/* Status */}
                    <div>
                        <label className="block text-gray-700 mb-2">Status</label>
                        <select
                            {...register("status", { required: "Status is required" })}
                            className="w-full p-2 border rounded"
                        >
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                        </select>
                        {errors.status && (
                            <p className="text-red-600 text-sm">{errors.status.message}</p>
                        )}
                    </div>

                    {/* Role */}
                    <div>
                        <label className="block text-gray-700 mb-2">Role</label>
                        <select
                            {...register("role", { required: "Role is required" })}
                            className="w-full p-2 border rounded"
                        >
                            <option value="admin">Admin</option>
                            <option value="editor">Editor</option>
                            <option value="viewer">Viewer</option>
                        </select>
                        {errors.role && (
                            <p className="text-red-600 text-sm">{errors.role.message}</p>
                        )}
                    </div>
                </div>

                {/* Access Pages */}
                <div>
                    <label className="block text-gray-700 mb-2">Access Pages</label>
                    <div className="space-x-4">
                        <label className="flex items-center">
                            <input
                                {...register("access_pages", {
                                    required: "At least one access right is required",
                                })}
                                type="checkbox"
                                value="Mill"
                                className="mr-2"
                            />
                            Mill
                        </label>
                        <label className="flex items-center">
                            <input
                                {...register("access_pages", {
                                    required: "At least one access right is required",
                                })}
                                type="checkbox"
                                value="Gate"
                                className="mr-2"
                            />
                            Gate
                        </label>
                        <label className="flex items-center">
                            <input
                                {...register("access_pages", {
                                    required: "At least one access right is required",
                                })}
                                type="checkbox"
                                value="Loading"
                                className="mr-2"
                            />
                            Loading
                        </label>
                        <label className="flex items-center">
                            <input
                                {...register("access_pages", {
                                    required: "At least one access right is required",
                                })}
                                type="checkbox"
                                value="Admin"
                                className="mr-2"
                            />
                            Admin
                        </label>
                        <label className="flex items-center">
                            <input
                                {...register("access_pages", {
                                    required: "At least one access right is required",
                                })}
                                type="checkbox"
                                value="Viewer"
                                className="mr-2"
                            />
                            Viewer
                        </label>
                    </div>
                    {errors.accessRights && (
                        <p className="text-red-600 text-sm">{errors.accessRights.message}</p>
                    )}
                </div>

                {/* Submit Button */}
                <div className="text-right">
                    <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded">
                        Update User
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UpdateUserPage;
