import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { EyeIcon, PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import axios from "../api/axios";
import Loader from "../components/common/Loader";
import MessageAlert from "../components/common/MessageAlert";

const ConsignmentManagementPage = () => {
    const [data, setData] = useState([]); // Consignment data
    const [loading, setLoading] = useState(false); // Loading state
    const [successMsg, setSuccessMsg] = useState(""); // Error state
    const [error, setError] = useState(""); // Error state

    // Fetch consignments on component mount
    useEffect(() => {
        const fetchConsignments = async () => {
            setLoading(true);
            setError("");
            try {
                const response = await axios.get("/consignment");
                setData(response.data); // Assuming API returns { consignments: [...] }
            } catch (err) {
                setData([]);
                setError("Failed to fetch consignments. Please try again later.");
                console.error("API Error:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchConsignments();
    }, []);

    // Define table columns
    const columns = useMemo(
        () => [
            {
                Header: "Sr. No",
                // Render row index + 1 for serial number
                Cell: ({ row }) => row.index + 1,
            },
            { Header: "CODE.", accessor: "consignmentNo" },
            { Header: "Supplier", accessor: "supplier" },
            { Header: "Vessel", accessor: "vessel" },
            {
                Header: "Created Date",
                accessor: "created_at",
                Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : "-"),
            },
            {
                Header: "Date Modified",
                accessor: "updated_at",
                Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : "-"),
            },
            { Header: "Total Records", accessor: "consignmentdatacount" },
            {
                Header: "Actions",
                Cell: ({ row }) => (
                    // Inside the Actions Cell
                    <div className="flex space-x-2">
                        <Link
                            to={`/viewconsignment/${row.original.id}`}
                            className="text-blue-600 hover:underline flex items-center"
                        >
                            <EyeIcon className="w-5 h-5 mr-1" />
                            View
                        </Link>
                        <Link
                            to={`/add-edit-consignment/${row.original.id}`}
                            className="text-green-600 hover:underline flex items-center"
                        >
                            <PencilIcon className="w-5 h-5 mr-1" />
                            Edit
                        </Link>
                        <button
                            onClick={() => handleDelete(row.original.id)}
                            className="text-red-600 hover:underline flex items-center"
                        >
                            <TrashIcon className="w-5 h-5 mr-1" />
                            Delete
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    // React Table hooks
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state,
        setGlobalFilter,
    } = useTable({ columns, data }, useGlobalFilter, usePagination);

    // Handle Delete Action
    const handleDelete = async (id) => {
        if (window.confirm(`Are you sure you want to delete this consignment  ID - (${id})?`)) {
            try {
                await axios.delete(`/consignment/${id}`);
                setData((prevData) => prevData.filter((consignment) => consignment.id !== id));
                setError(null);
                setSuccessMsg("Consignment deleted successfully.");
            } catch (err) {
                if (err.response && err.response.data.message) {
                    setError(err.response.data.message); // Show specific error from API
                } else {
                    setError("Failed to delete consignment. Please try again.");
                }
            }
        }
    };

    return (
        <div className="p-6">
            {/* Page Header */}
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold text-gray-800">Consignment Management</h1>
                <Link
                    to="/add-log"
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
                >
                    <PlusIcon className="w-5 h-5 mr-2" />
                    Add Log
                </Link>
                <Link
                    to="/add-edit-consignment"
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
                >
                    <PlusIcon className="w-5 h-5 mr-2" />
                    Upload Consignments
                </Link>
            </div>

            {/* Loading State */}
            {loading && <Loader />}

            {successMsg && (
                <MessageAlert
                    type="success"
                    message={successMsg}
                    onClose={() => setSuccessMsg("")}
                />
            )}

            {/* Error State */}
            {error && <MessageAlert type="error" message={error} onClose={() => setError("")} />}

            {/* Search Filter */}
            {!loading && !error && (
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Search consignments..."
                        value={state.globalFilter || ""}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
            )}

            {/* Consignments Table */}
            {!loading && !error && data.length > 0 && (
                <div className="overflow-x-auto bg-white shadow rounded-lg">
                    <table
                        {...getTableProps()}
                        className="min-w-full bg-white border border-gray-200"
                    >
                        <thead className="bg-gray-100">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className="px-4 py-2 text-left text-sm font-medium text-gray-600"
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="border-b last:border-none hover:bg-gray-50"
                                    >
                                        {row.cells.map((cell) => (
                                            <td
                                                {...cell.getCellProps()}
                                                className="px-4 py-2 text-sm text-gray-800"
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {/* No Consignments Found */}
            {!loading && !error && data.length === 0 && (
                <p>No consignments found. Please add some consignments.</p>
            )}
        </div>
    );
};

export default ConsignmentManagementPage;
