import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../api/axios";
import { Breadcrumbs } from "@mui/material";

const UpdateSpeciesPage = () => {
  const { id } = useParams(); // Species ID from the URL
  const navigate = useNavigate();
  const [mainCategories, setMainCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const mainCategory = watch("mainCategory");

  // Fetch species details and main categories
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        // Fetch main categories
        const mainResponse = await axios.get("/categories/main");
        setMainCategories(mainResponse.data.mainCategories);

        // Fetch species details
        const speciesResponse = await axios.get(`/species/${id}`);
        const species = speciesResponse.data.species;

        setValue("name", species.name);
        setValue("code", species.code);
        setValue("mainCategory", String(species.main_category.id));

      } catch (err) {
        setError("Failed to load species details.");
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [id, setValue]);


  const onSubmit = async (data) => {
    setError("");
    setSaving(true);
    try {
      await axios.put(`/species/${id}`, {
        name: data.name,
        code: data.code,
        main_category: data.mainCategory,
      });
      navigate("/speciesManagement");
    } catch (err) {
      if (err.response && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError("Failed to update species. Please try again.");
      }
     } finally {
        setSaving(false);
      }
  };

  if (loading) {
    return <div className="text-center mt-10">Loading species details...</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Update Species</h1>

      <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    className="text-gray-600 text-xs font-bold"
                    to="/speciesManagement"
                >
                    Species Management
                </Link>
                <Link underline="hover" className="text-gray-600 text-xs font-bold" href="#">
                    Update Species
                </Link>
            </Breadcrumbs>

      {error && <div className="bg-red-100 text-red-800 p-3 rounded mb-4">{error}</div>}

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 bg-white p-6 shadow rounded">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Name Field */}
          <div>
            <label className="block text-gray-700 mb-2">Name</label>
            <input
              {...register("name", { required: "Name is required." })}
              type="text"
              className={`w-full p-2 border rounded ${
                errors.name ? "border-red-500" : "border-gray-300"
              }`}
              disabled={saving}
            />
            {errors.name && <p className="text-red-600 text-sm mt-1">{errors.name.message}</p>}
          </div>

          {/* Code Field */}
          <div>
            <label className="block text-gray-700 mb-2">Code</label>
            <input
              {...register("code", { required: "Code is required." })}
              type="text"
              className={`w-full p-2 border rounded ${
                errors.code ? "border-red-500" : "border-gray-300"
              }`}
              disabled={saving}
            />
            {errors.code && <p className="text-red-600 text-sm mt-1">{errors.code.message}</p>}
          </div>
        </div>
          
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Main Category Radio Buttons */}
        <div>
          <label className="block text-gray-700 mb-2">Main Category</label>
          <div className="flex flex-wrap gap-4">
            {mainCategories.map((category) => (
              <div key={category.id} className="flex items-center">
                <input
                  {...register("mainCategory", {
                    required: "Main category must be selected.",
                  })}
                  type="radio"
                  id={`main-category-${category.id}`}
                  value={category.id}
                  className="mr-2"
                  disabled={saving}
                />
                <label htmlFor={`main-category-${category.id}`}>{category.name}</label>
              </div>
            ))}
          </div>
          {errors.mainCategory && (
            <p className="text-red-600 text-sm mt-1">{errors.mainCategory.message}</p>
          )}
        </div>

        </div>
        
        {/* Submit Button */}
        <div className="text-right">
          <button
            type="submit"
            className={`bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 ${
              saving ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={saving}
          >
            {saving ? "Updating..." : "Update Species"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateSpeciesPage;
