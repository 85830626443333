import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";

const UpdateSubcategoryPage = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [parentId, setParentId] = useState("");
  const [mainCategories, setMainCategories] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); // For form loading state
  const [submitting, setSubmitting] = useState(false); // For submit action
  const navigate = useNavigate();

  // Fetch main categories and subcategory details
  useEffect(() => {
    const fetchData = async () => {
      setError("");
      setLoading(true);
      try {
        // Fetch main categories
        const mainResponse = await axios.get("/categories/main");
        setMainCategories(mainResponse.data.mainCategories);

        // Fetch subcategory details
        const subResponse = await axios.get(`/categories/sub/${id}`);
        const subcategory = subResponse.data.subcategory;
        setName(subcategory.name);
        setParentId(subcategory.parent_id);
      } catch (err) {
        setError("Failed to load subcategory details.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSubmitting(true); // Start submit loader
    try {
      await axios.put(`/categories/sub/${id}`, { name, parent_id: parentId });
      // alert("Subcategory updated successfully!");
      navigate("/categoryManagement");
    } catch (err) {
      if (err.response && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError("Failed to update subcategory. Please try again.");
      }
    } finally {
      setSubmitting(false); // Stop submit loader
    }
  };

  if (loading) {
    return <div className="text-center mt-10">Loading subcategory details...</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Update Subcategory</h1>

      {error && <div className="bg-red-100 text-red-800 p-3 rounded mb-4">{error}</div>}

      <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 shadow rounded">
        <div>
          <label className="block text-gray-700 mb-2">Main Category</label>
          <select
            value={parentId}
            onChange={(e) => setParentId(e.target.value)}
            className="w-full p-2 border rounded"
            required
            disabled={submitting} // Disable during submit
          >
            <option value="">Select Main Category</option>
            {mainCategories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-gray-700 mb-2">Subcategory Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border rounded"
            required
            disabled={submitting} // Disable during submit
          />
        </div>

        <button
          type="submit"
          className={`bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 ${
            submitting ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={submitting} // Disable button during submit
        >
          {submitting ? "Updating..." : "Update Subcategory"}
        </button>
      </form>
    </div>
  );
};

export default UpdateSubcategoryPage;
